html.mobile div.top-left-hud {
  top: 0 !important;
  left: unset !important;
  right: unset !important;
}

html.mobile div.top-center-hud {
  top: 25px !important;
  transform: scale(.65) !important;
}

html.mobile div.top-center-hud div.height, html.mobile div.top-center-hud div.falls {
  display: none !important;
}

@media screen and (max-width: 500px) {
  html.mobile div.loading-splash .game-logo {
    height: 345px;
    position: relative;
    top: 25px;
  }

  html.mobile div.loading-splash .loading {
    position: relative;
    top: -55px;
    transform: scale(.75) !important;
  }
}

@media screen and (max-width: 450px) {
  html.mobile div.loading-splash .game-logo {
    height: 315px;
    position: relative;
    top: 25px;
  }

  html.mobile div.loading-splash .loading {
    position: relative;
    top: -55px;
    transform: scale(.75) !important;
  }
}

@media screen and (max-width: 400px) {
  html.mobile div.loading-splash .game-logo {
    height: 275px;
    position: relative;
    top: 25px;
  }

  html.mobile div.loading-splash .loading {
    position: relative;
    top: -55px;
    transform: scale(.65) !important;
  }
}

@media screen and (max-width: 350px) {
  html.mobile div.loading-splash .game-logo {
    height: 235px;
    position: relative;
    top: 25px;
  }

  html.mobile div.loading-splash .loading {
    position: relative;
    top: -55px;
    transform: scale(.55) !important;
  }
}

@media screen and (max-width: 300px) {
  html.mobile div.loading-splash .game-logo {
    height: 215px;
    position: relative;
    top: 25px;
  }

  html.mobile div.loading-splash .loading {
    position: relative;
    top: -55px;
    transform: scale(.45) !important;
  }
}

@media screen and (max-width: 3841px), (max-height: 2161px) {
  div.loading-splash .game-logo {
    height: 950px;
    position: relative;
    top: 25px;
  }

  div.loading-splash .loading {
    position: relative;
    top: -75px;
    transform: scale(1.55) !important;
  }

  div.top-left-hud {
    top: 35px !important;
    left: 150px !important;
  }

  div.top-center-hud {
    top: 35px !important;
  }

  div.change-username {
    transform: scale(2.25) !important;
  }

  div.emoji-selector {
    transform: scale(2.5) !important;
  }

  div.run-complete {
    transform: scale(2.25) !important;
  }

  div.character-select, div.trail-select, div.restart-run-warning, div.view-settings {
    transform: scale(1.85) !important;
  }

  .play-btn {
    bottom: 150px !important;
    transform: scale(5) !important;
  }

  div.ui-pop-loader {
    bottom: 100px !important;
    transform: scale(2.5) !important;
  }

  div.hud p.alert {
    margin-top: 10% !important;
    transform: scale(2) !important;
  }
}

@media screen and (max-width: 2561px), (max-height: 1441px) {
  div.loading-splash .game-logo {
    height: 950px;
    position: relative;
    top: 25px;
  }

  div.loading-splash .loading {
    position: relative;
    top: -75px;
    transform: scale(1.55) !important;
  }

  div.top-left-hud {
    top: 35px !important;
    left: 150px !important;
  }

  div.top-center-hud {
    top: 35px !important;
  }

  div.change-username {
    transform: scale(2.25) !important;
  }

  div.emoji-selector {
    transform: scale(2.5) !important;
  }

  div.run-complete {
    transform: scale(2.25) !important;
  }

  div.character-select, div.trail-select, div.restart-run-warning, div.view-settings {
    transform: scale(1.85) !important;
  }

  .play-btn {
    bottom: 150px !important;
    transform: scale(5) !important;
  }

  div.ui-pop-loader {
    bottom: 100px !important;
    transform: scale(2.5) !important;
  }

  div.hud p.alert {
    margin-top: 10% !important;
    transform: scale(2) !important;
  }
}

@media screen and (max-width: 1921px), (max-height: 1081px) {
  div.loading-splash .game-logo {
    height: 750px;
    position: relative;
    top: 25px;
  }

  div.loading-splash .loading {
    position: relative;
    top: -75px;
    transform: scale(1.25) !important;
  }

  div.top-left-hud {
    left: 50px !important;
  }

  div.top-center-hud {
    top: 15px !important;
  }

  div.change-username, div.emoji-selector {
    transform: scale(1.65) !important;
  }

  div.run-complete {
    transform: scale(1.75) !important;
  }

  div.character-select, div.trail-select, div.restart-run-warning, div.view-settings {
    transform: scale(1.55) !important;
  }

  .play-btn {
    bottom: 150px !important;
    transform: scale(3.25) !important;
  }

  div.ui-pop-loader {
    bottom: 75px !important;
    transform: scale(1.75) !important;
  }

  div.hud p.alert {
    margin-top: 10% !important;
    transform: scale(1.25) !important;
  }
}

@media screen and (max-width: 1601px), (max-height: 901px) {
  div.loading-splash .game-logo {
    height: 650px;
    position: relative;
    top: 25px;
  }

  div.loading-splash .loading {
    position: relative;
    top: -75px;
  }

  div.top-center-hud {
    top: 15px !important;
  }

  div.change-username {
    transform: scale(1.25) !important;
  }

  div.emoji-selector {
    transform: scale(1.45) !important;
  }

  div.run-complete {
    transform: scale(1.5) !important;
  }

  div.character-select, div.trail-select, div.restart-run-warning, div.view-settings {
    transform: scale(1.25) !important;
  }

  .play-btn {
    bottom: 100px !important;
    transform: scale(3) !important;
  }

  div.ui-pop-loader {
    bottom: 75px !important;
    transform: scale(1.75) !important;
  }

  div.hud p.alert {
    margin-top: 10% !important;
    transform: scale(1.25) !important;
  }
}

@media screen and (max-width: 1441px), (max-height: 901px) {
  div.loading-splash .game-logo {
    height: 650px;
    position: relative;
    top: 25px;
  }

  div.loading-splash .loading {
    position: relative;
    top: -75px;
  }

  div.change-username {
    transform: scale(1.25) !important;
  }

  div.emoji-selector {
    transform: scale(1.45) !important;
  }

  div.run-complete {
    transform: scale(1.5) !important;
  }

  div.character-select, div.trail-select, div.restart-run-warning, div.view-settings {
    transform: scale(1.25) !important;
  }

  .play-btn {
    bottom: 100px !important;
    transform: scale(3) !important;
  }

  div.ui-pop-loader {
    bottom: 75px !important;
    transform: scale(1.75) !important;
  }

  div.hud p.alert {
    margin-top: 10% !important;
    transform: scale(1.25) !important;
  }
}

@media screen and (max-width: 1377px), (max-height: 769px) {
  div.loading-splash .game-logo {
    height: 500px;
    position: relative;
    top: 25px;
  }

  div.loading-splash .loading {
    position: relative;
    top: -55px;
  }

  div.change-username {
    transform: scale(.85) !important;
  }

  div.emoji-selector {
    transform: scale(.95) !important;
  }

  div.run-complete {
    transform: scale(1) !important;
  }

  div.character-select, div.trail-select, div.restart-run-warning, div.view-settings {
    transform: scale(.85) !important;
  }

  .play-btn {
    bottom: 35px !important;
    transform: scale(2.25) !important;
  }

  div.ui-pop-loader {
    bottom: 35px !important;
    transform: scale(1.25) !important;
  }

  div.hud p.alert {
    margin-top: 10% !important;
    transform: scale(.75) !important;
  }
}

@media screen and (max-width: 1281px), (max-height: 721px) {
  div.loading-splash .game-logo {
    height: 500px;
    position: relative;
    top: 25px;
  }

  div.loading-splash .loading {
    position: relative;
    top: -55px;
  }

  div.change-username {
    transform: scale(.85) !important;
  }

  div.emoji-selector {
    transform: scale(.95) !important;
  }

  div.run-complete {
    transform: scale(1) !important;
  }

  div.character-select, div.trail-select, div.restart-run-warning, div.view-settings {
    transform: scale(.85) !important;
  }

  .play-btn {
    bottom: 35px !important;
    transform: scale(2.25) !important;
  }

  div.ui-pop-loader {
    bottom: 35px !important;
    transform: scale(1.25) !important;
  }

  div.hud p.alert {
    margin-top: 10% !important;
    transform: scale(.75) !important;
  }
}

@media screen and (max-width: 1032px), (max-height: 581px) {
  div.loading-splash .game-logo {
    height: 500px;
    position: relative;
    top: 50px;
  }

  div.loading-splash .loading {
    position: relative;
    top: -55px;
    transform: scale(.85) !important;
  }

  div.emoji-selector {
    transform: scale(.95) !important;
  }

  div.run-complete {
    transform: scale(1) !important;
  }

  div.character-select, div.trail-select, div.restart-run-warning, div.view-settings {
    transform: scale(.85) !important;
  }

  .play-btn {
    bottom: 35px !important;
    transform: scale(2.25) !important;
  }

  div.ui-pop-loader {
    bottom: 35px !important;
    transform: scale(1.25) !important;
  }

  div.hud p.alert {
    margin-top: 10% !important;
    transform: scale(.75) !important;
  }

  div.top-center-hud {
    top: 0 !important;
    transform: scale(1) !important;
  }

  div.change-username {
    transform: scale(.85) !important;
  }

  div.top-left-hud {
    top: 5px !important;
    left: 5px !important;
  }
}

@media screen and (max-width: 837px) {
  .top-ui-container {
    margin-top: 10px;
    justify-content: space-around !important;
    gap: 25px !important;
  }

  .top-ui-container .top-center-hud, .top-ui-container .run-duration {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 837px), (max-height: 471px) {
  div.loading-splash .game-logo {
    height: 350px;
    position: relative;
    top: 50px;
  }

  div.loading-splash .loading {
    position: relative;
    top: -25px;
    transform: scale(.75) !important;
  }

  div.change-username {
    transform: scale(.73) !important;
  }

  div.emoji-selector {
    transform: scale(.75) !important;
  }

  div.run-complete {
    transform: scale(.85) !important;
  }

  div.character-select, div.trail-select, div.restart-run-warning, div.view-settings {
    transform: scale(.69) !important;
  }

  .play-btn {
    bottom: 5px !important;
    transform: scale(1.75) !important;
  }

  div.ui-pop-loader {
    bottom: 35px !important;
    transform: scale(1.25) !important;
  }

  div.hud p.alert {
    margin-top: 10% !important;
    transform: scale(.75) !important;
  }

  div.top-left-hud {
    top: -5px !important;
    left: unset !important;
    right: unset !important;
  }

  div.top-center-hud {
    top: 25px !important;
    transform: scale(.75) !important;
  }

  div.top-center-hud div.falls {
    top: -51px !important;
    left: -90px !important;
  }

  div.top-center-hud div.height {
    top: -40px !important;
    left: 222px !important;
  }
}

@media screen and (max-width: 641px), (max-height: 361px) {
  div.loading-splash .game-logo {
    height: 300px;
    position: relative;
    top: 55px;
  }

  div.loading-splash .loading {
    position: relative;
    top: -50px;
    transform: scale(.65) !important;
  }

  div.change-username {
    transform: scale(.5) !important;
  }

  div.emoji-selector, div.run-complete {
    transform: scale(.6) !important;
  }

  div.character-select, div.trail-select, div.restart-run-warning, div.view-settings {
    transform: scale(.47) !important;
  }

  .play-btn {
    bottom: 5px !important;
    transform: scale(1.5) !important;
  }

  div.ui-pop-loader {
    bottom: 5px !important;
    transform: scale(1) !important;
  }

  div.top-left-hud {
    top: 0 !important;
    left: unset !important;
    right: unset !important;
  }

  div.hud p.alert {
    margin-top: 10% !important;
    transform: scale(.75) !important;
  }

  div.top-center-hud {
    top: 25px !important;
    transform: scale(.65) !important;
  }

  div.top-center-hud div.falls {
    top: -51px !important;
    left: -90px !important;
  }

  div.top-center-hud div.height {
    top: -40px !important;
    left: 222px !important;
  }
}

@font-face {
  font-family: Material Icons;
  font-style: normal;
  font-weight: 400;
  src: url("material-icons.10edc7fe.woff2") format("woff2");
}

.material-icons {
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  font-family: Material Icons;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

@font-face {
  font-family: Luckiest Guy;
  src: url("LuckiestGuy-Regular.46f2bef8.ttf") format("truetype");
}

img.hidden-preload {
  display: none !important;
}

img {
  -webkit-user-select: none;
  user-select: none;
}

* {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  font-family: Luckiest Guy, sans-serif;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

option {
  color: #000 !important;
}

body {
  background-color: #282828;
  justify-content: center;
  align-items: center;
  display: flex;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-zoom: none;
}

#stats {
  justify-content: center;
  align-items: center;
  display: none;
  position: absolute;
  bottom: 0;
}

#stats canvas {
  display: flex !important;
}

#game-container {
  background-color: #000;
  border: none;
  border-radius: 0;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

#ui-root {
  pointer-events: none;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.screen-router {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

@keyframes loading-bar-animation {
  0% {
    -webkit-mask-size: 0% 300%;
    mask-size: 0% 300%;
  }

  100% {
    -webkit-mask-size: 100% 300%;
    mask-size: 100% 300%;
  }
}

div.loading-splash {
  z-index: 10;
  pointer-events: none;
  background-image: url("UpTogether_SplashBackground.d957f8e8.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
}

div.loading-splash div.error-screen {
  display: none;
}

div.loading-splash div.error-screen h3.error-msg {
  text-align: center;
  text-shadow: 0 0 5px #000;
  color: #fff;
  letter-spacing: 1px;
  max-width: 400px;
  margin: 0;
  padding: 0 30px;
  font-size: 36px;
  line-height: 45px;
}

div.loading-splash div.error-screen h3.error-msg.error {
  color: red;
}

div.loading-splash .smg-logo {
  text-align: center;
  color: #e6b23e;
  text-shadow: 0 0 3px #000;
  align-self: flex-end;
  position: absolute;
  bottom: 0;
}

div.loading-splash .smg-logo img {
  height: 100px;
  position: relative;
  top: 20px;
}

div.loading-splash div.loading {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

div.loading-splash div.loading div.loading-bar {
  width: 512px;
  height: 128px;
  position: relative;
  overflow: hidden;
}

div.loading-splash div.loading div.loading-bar img.loading-bar-bg {
  width: 100%;
  height: 100%;
  position: absolute;
}

div.loading-splash div.loading div.loading-bar img.loading-bar-fg {
  width: 100%;
  height: 100%;
  animation: .3s all;
  position: absolute;
  -webkit-mask-image: linear-gradient(#0000, #000, #0000);
  mask-image: linear-gradient(#0000, #000, #0000);
  -webkit-mask-position: 0;
  mask-position: 0;
  -webkit-mask-size: 0% 300%;
  mask-size: 0% 300%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

div.loading-splash div.loading div.loading-text {
  text-align: center;
  color: #fff;
  letter-spacing: 2px;
  text-shadow: 0 0 4px #000;
  max-width: 450px;
  height: 60px;
  margin-top: 16px;
  font-size: 24px;
}

.ui-screen-open-overlay {
  pointer-events: none;
  z-index: 1;
  background-color: #ffffff40;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

div.hud {
  touch-action: manipulation;
  z-index: 500;
  pointer-events: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
}

div.hud .joystick-container {
  width: 200px;
  height: 200px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  pointer-events: all !important;
}

div.hud .joystick-container .joystick-container-inner {
  width: 100%;
  height: 100%;
  position: relative;
}

div.hud .jump-button-container {
  display: none;
  position: absolute;
  bottom: 85px;
  right: 35px;
}

div.hud .jump-button-container .jump-button {
  color: #fff;
  letter-spacing: 2px;
  pointer-events: all;
  background-color: #b387c6;
  border: none;
  border-radius: 5px;
  padding: 20px 20px 7px;
  font-size: 42px;
  display: flex;
  transform: scale(1);
  box-shadow: 0 2px 2px 1px #00000040;
  z-index: 5 !important;
}

div.hud .jump-button-container .jump-button:hover {
  color: #b387c6;
  cursor: pointer;
  background-color: #fff;
}

div.hud .jump-button-container .jump-button:active {
  box-shadow: none;
  transform: scale(.95);
}

div.hud p.alert {
  pointer-events: none;
  color: #fff;
  text-shadow: 0 0 10px #000;
  text-align: center;
  margin-top: 8%;
  padding: 30px 0 0;
  font-size: 90px;
  display: none;
}

div.hud div.play-btn {
  color: #fff;
  letter-spacing: 2px;
  pointer-events: all;
  background-color: #b387c6;
  border: none;
  border-radius: 5px;
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 42px;
  display: flex;
  position: fixed;
  bottom: 100px;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 2px 2px 1px #00000040;
  z-index: 5 !important;
}

body.ios div.hud div.play-btn {
  padding: 12px 14px 0 18px;
}

body.windows div.hud div.play-btn {
  padding: 0 14px 0 18px;
}

div.hud div.play-btn:hover {
  color: #b387c6;
  cursor: pointer;
  background-color: #fff;
}

div.hud div.play-btn:active {
  box-shadow: none;
  transform: scale(2.75);
}

div.hud div.top-center-hud {
  flex-direction: row;
  flex: none;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  display: flex;
}

div.hud div.top-center-hud .run-duration {
  flex: auto;
}

div.hud div.top-center-hud div.height, div.hud div.top-center-hud div.falls {
  text-shadow: 0 0 5px #000;
  color: #fff;
  flex: none;
  height: 43px;
  font-size: 24px;
  top: 30px;
}

div.hud div.top-center-hud div.height span.material-icons, div.hud div.top-center-hud div.falls span.material-icons {
  position: relative;
  left: -8px;
}

div.hud div.top-center-hud div.height span, div.hud div.top-center-hud div.falls span {
  font-size: 36px;
  position: relative;
}

div.hud div.top-center-hud .height-metric {
  top: -3px;
  font-size: unset !important;
}

div.hud div.top-center-hud div.falls {
  top: 25px;
  left: -100px;
}

div.hud div.top-center-hud div.falls .material-icons {
  top: -6px;
}

div.hud div.top-center-hud div.height {
  top: 35px;
  left: 250px;
}

div.hud div.top-center-hud .falls-value {
  vertical-align: top;
}

div.hud div.top-center-hud div.run-duration {
  text-align: center;
  color: #fff;
  text-shadow: 0 0 10px #000;
  width: 240px;
  height: 100%;
  font-size: 72px;
}

div.hud div.top-center-hud div.run-duration.complete {
  animation: 8s linear infinite colorTransition;
}

div.emoji-selector {
  pointer-events: none;
  width: 100px;
  height: 100px;
  display: none;
}

div.emoji-selector div.close-screen {
  pointer-events: all;
  cursor: pointer;
  color: #fff;
  z-index: 100;
  letter-spacing: 2px;
  background-color: #b387c6;
  border: none;
  border-radius: 5px;
  height: 70px;
  font-size: 48px;
  position: absolute;
  top: -200px;
  right: -300px;
  box-shadow: 0 2px 2px 1px #00000040;
}

body.ios div.emoji-selector div.close-screen {
  padding: 16px 12px 60px 14px;
  font-size: 64px;
}

body.windows div.emoji-selector div.close-screen {
  padding: 6px 12px 16px 14px;
}

div.emoji-selector div.close-screen:hover {
  color: #b387c6;
  cursor: pointer;
  background-color: #fff;
}

div.emoji-selector div.close-screen:active {
  box-shadow: none;
  transform: scale(.95);
}

div.emoji-selector div.emoji-selector-inner {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: relative;
}

div.emoji-selector div.emoji-selector-inner img {
  z-index: 999;
  pointer-events: all;
  cursor: pointer;
  transition: all .15s;
  position: absolute;
  top: -500px;
  left: -500px;
  transform: scale(.5);
}

div.emoji-selector div.emoji-selector-inner img:hover {
  transform: scale(.6);
}

div.emoji-selector div.emoji-selector-inner img.apple {
  top: -75px;
  left: -75px;
}

div.emoji-selector div.emoji-selector-inner img.baguette {
  top: -150px;
  left: -145px;
}

div.emoji-selector div.emoji-selector-inner img.blueberry {
  top: 15px;
  left: 15px;
}

div.emoji-selector div.emoji-selector-inner img.broccoli {
  top: 55px;
  left: -115px;
}

div.emoji-selector div.emoji-selector-inner img.hat {
  top: -115px;
  left: 45px;
}

div.emoji-selector div.emoji-selector-inner img.come {
  top: -200px;
  left: -55px;
}

div.emoji-selector div.emoji-selector-inner img.cupcake {
  top: -95px;
  left: -305px;
}

div.emoji-selector div.emoji-selector-inner img.eggplant {
  top: -175px;
  left: -235px;
}

div.emoji-selector div.emoji-selector-inner img.fly {
  top: 35px;
  left: -265px;
}

div.emoji-selector div.emoji-selector-inner img.icecream {
  top: -55px;
  left: 150px;
}

div.emoji-selector div.emoji-selector-inner img.invis {
  top: 55px;
  left: 115px;
}

div.emoji-selector div.emoji-selector-inner img.peach {
  top: -175px;
  left: 165px;
}

div.emoji-selector div.emoji-selector-inner img.spatula {
  top: -45px;
  left: -205px;
}

div.ui-screen-parent {
  border: 50px #000;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

div.change-username {
  pointer-events: all;
  background-color: #f2b860;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 600px;
  display: none;
  transform: scale(1.3);
  box-shadow: 0 10px 10px #00000040;
}

div.change-username div.change-username-inner {
  display: center;
  width: 100%;
  height: 100%;
  position: relative;
}

div.change-username div.change-username-inner div.title {
  color: #b387c6;
  text-align: center;
  letter-spacing: 2px;
  z-index: 99;
  background-color: #efd7a2;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  height: 100px;
  padding: 23px 0 30px 34px;
  font-size: 48px;
}

div.change-username div.change-username-inner div.input-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  display: flex;
}

div.change-username div.change-username-inner div.input-container div.input {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

div.change-username div.change-username-inner div.input-container div.input img.flag-preview {
  position: absolute;
  transform: scale(.75);
}

div.change-username div.change-username-inner div.input-container div.input img.flag-preview.left {
  top: 39px;
  left: -75px;
}

div.change-username div.change-username-inner div.input-container div.input img.flag-preview.right {
  top: 39px;
  right: -75px;
}

div.change-username div.change-username-inner div.input-container div.input:nth-of-type(2) {
  margin-top: 20px;
}

div.change-username div.change-username-inner div.input-container div.input h3 {
  text-shadow: 0 0 5px #000;
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 36px;
}

div.change-username div.change-username-inner div.input-container div.input select {
  color: #fff;
  text-align: center;
  background-color: #b387c6;
  border: 0;
  border-radius: 5px;
  width: 300px;
  height: 50px;
  margin-bottom: 10px;
  padding: 10px 0 0 10px;
  font-size: 36px;
}

div.change-username div.change-username-inner div.input-container div.input select:nth-of-type(2), div.change-username div.change-username-inner div.input-container div.input select:nth-of-type(3), div.change-username div.change-username-inner div.button-container {
  text-align: center;
}

div.change-username div.change-username-inner button.change-username-btn {
  color: #fff;
  letter-spacing: 2px;
  background-color: #b387c6;
  border: none;
  border-radius: 5px;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 13px 12px 10px 13px;
  font-size: 42px;
  box-shadow: 0 2px 2px 1px #00000040;
}

div.change-username div.change-username-inner button.change-username-btn:hover {
  color: #b387c6;
  cursor: pointer;
  background-color: #fff;
}

div.change-username div.change-username-inner button.change-username-btn:active {
  box-shadow: none;
  transform: scale(.95);
}

div.change-username div.change-username-inner div.close-screen {
  cursor: pointer;
  color: #fff;
  z-index: 100;
  letter-spacing: 2px;
  background-color: #b387c6;
  border: none;
  border-radius: 5px;
  height: 70px;
  font-size: 48px;
  position: absolute;
  top: -30px;
  right: -30px;
  box-shadow: 0 2px 2px 1px #00000040;
}

body.ios div.change-username div.change-username-inner div.close-screen {
  padding: 16px 12px 60px 14px;
  font-size: 64px;
}

body.windows div.change-username div.change-username-inner div.close-screen {
  padding: 6px 12px 16px 14px;
}

div.change-username div.change-username-inner div.close-screen:hover {
  color: #b387c6;
  cursor: pointer;
  background-color: #fff;
}

div.change-username div.change-username-inner div.close-screen:active {
  box-shadow: none;
  transform: scale(.95);
}

.top-ui-container {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.top-ui-container > * {
  flex: none;
  transform: none !important;
}

.top-ui-container > :last-child {
  width: 360px;
}

.top-ui-container > .top-center-hud {
  width: auto;
}

div.top-left-hud {
  justify-content: center;
  align-items: center;
  display: flex;
}

div.top-left-hud span.keyboard-keypress {
  position: relative;
  top: 2px;
  left: 8px;
  color: #fff !important;
  font-size: 20px !important;
}

div.top-left-hud span.keyboard-keypress.esc {
  left: -1px;
}

div.top-left-hud span.keyboard-keypress.f, div.top-left-hud span.keyboard-keypress.p {
  left: 8px;
}

div.top-left-hud span.keyboard-keypress.r {
  top: -3px;
  left: 7px;
}

div.top-left-hud span.keyboard-keypress.f10 {
  left: -4px;
}

div.top-left-hud span.keyboard-keypress.m {
  left: 4px;
}

div.top-left-hud div.restart-cp-btn {
  pointer-events: all;
  cursor: pointer;
  background-color: #efd7a2;
  border: 3px solid #fff;
  border-radius: 5px;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  display: none;
  position: relative;
}

div.top-left-hud div.restart-cp-btn img {
  width: 34px;
  height: 34px;
  position: relative;
  top: -4px;
  left: 5px;
}

body.ios div.top-left-hud div.restart-cp-btn {
  padding-top: 9px;
}

div.top-left-hud div.restart-cp-btn:hover {
  opacity: 1;
  transform: scale(1.1);
}

div.top-left-hud div.restart-cp-btn:active {
  transform: scale(.75);
}

div.top-left-hud div.restart-cp-btn span {
  color: #b387c6;
  font-size: 45px;
}

div.top-left-hud div.mute-music-btn, div.top-left-hud div.mute-sfx-btn, div.top-left-hud div.restart-run-btn, div.top-left-hud div.fs-btn, div.top-left-hud div.disc-btn, div.top-left-hud div.emoji-btn, div.top-left-hud div.settings-btn {
  pointer-events: all;
  cursor: pointer;
  background-color: #efd7a2;
  border: 3px solid #fff;
  border-radius: 5px;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
}

div.top-left-hud div.mute-music-btn img, div.top-left-hud div.mute-sfx-btn img, div.top-left-hud div.restart-run-btn img, div.top-left-hud div.fs-btn img, div.top-left-hud div.disc-btn img, div.top-left-hud div.emoji-btn img, div.top-left-hud div.settings-btn img {
  width: 34px;
  height: 34px;
  position: relative;
  top: -4px;
  left: 5px;
}

div.top-left-hud div.mute-music-btn.restart-run-btn, div.top-left-hud div.mute-sfx-btn.restart-run-btn, div.top-left-hud div.restart-run-btn.restart-run-btn, div.top-left-hud div.fs-btn.restart-run-btn, div.top-left-hud div.disc-btn.restart-run-btn, div.top-left-hud div.emoji-btn.restart-run-btn, div.top-left-hud div.settings-btn.restart-run-btn {
  display: none;
}

div.top-left-hud div.mute-music-btn:hover, div.top-left-hud div.mute-sfx-btn:hover, div.top-left-hud div.restart-run-btn:hover, div.top-left-hud div.fs-btn:hover, div.top-left-hud div.disc-btn:hover, div.top-left-hud div.emoji-btn:hover, div.top-left-hud div.settings-btn:hover {
  opacity: 1;
  transform: scale(1.1);
}

div.top-left-hud div.mute-music-btn:active, div.top-left-hud div.mute-sfx-btn:active, div.top-left-hud div.restart-run-btn:active, div.top-left-hud div.fs-btn:active, div.top-left-hud div.disc-btn:active, div.top-left-hud div.emoji-btn:active, div.top-left-hud div.settings-btn:active {
  transform: scale(.75);
}

div.top-left-hud div.mute-music-btn span, div.top-left-hud div.mute-sfx-btn span, div.top-left-hud div.restart-run-btn span, div.top-left-hud div.fs-btn span, div.top-left-hud div.disc-btn span, div.top-left-hud div.emoji-btn span, div.top-left-hud div.settings-btn span {
  color: #b387c6;
  font-size: 45px;
}

div.top-left-hud div.mute-music-btn div.off, div.top-left-hud div.mute-sfx-btn div.off, div.top-left-hud div.restart-run-btn div.off, div.top-left-hud div.fs-btn div.off, div.top-left-hud div.disc-btn div.off, div.top-left-hud div.emoji-btn div.off, div.top-left-hud div.settings-btn div.off {
  z-index: 999;
  border-bottom: 4px solid #fff;
  width: 63px;
  height: 10px;
  display: flex;
  position: absolute;
  top: 15px;
  left: -12px;
  transform: rotate(-45deg);
}

div.top-left-hud div.mute-music-btn div.off.shown, div.top-left-hud div.mute-sfx-btn div.off.shown, div.top-left-hud div.restart-run-btn div.off.shown, div.top-left-hud div.fs-btn div.off.shown, div.top-left-hud div.disc-btn div.off.shown, div.top-left-hud div.emoji-btn div.off.shown, div.top-left-hud div.settings-btn div.off.shown {
  display: flex;
}

@keyframes colorTransition {
  0% {
    color: red;
  }

  14% {
    color: orange;
  }

  28% {
    color: #ff0;
  }

  42% {
    color: green;
  }

  57% {
    color: #00f;
  }

  71% {
    color: indigo;
  }

  85% {
    color: violet;
  }

  100% {
    color: red;
  }
}

div.run-complete {
  pointer-events: all;
  background-color: #f2b860;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  display: none;
  transform: scale(1.3);
  box-shadow: 0 10px 10px #00000040;
}

div.run-complete div.run-complete-inner {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

div.run-complete div.run-complete-inner div.title {
  color: #b387c6;
  text-align: center;
  letter-spacing: 2px;
  z-index: 99;
  background-color: #efd7a2;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  height: 100px;
  padding: 23px 0 30px 34px;
  font-size: 48px;
}

div.run-complete div.run-complete-inner div.run-details {
  margin: 50px 0 20px;
}

div.run-complete div.run-complete-inner div.run-details div.your-time-title {
  text-align: center;
  color: #fff;
  margin-bottom: 10px;
  font-size: 51px;
}

div.run-complete div.run-complete-inner div.run-details h2.your-time {
  text-align: center;
  color: #fff;
  margin: 0;
  padding-top: 25px;
  font-size: 96px;
  animation: 8s linear infinite colorTransition;
}

div.run-complete div.run-complete-inner div.close-screen {
  cursor: pointer;
  color: #fff;
  z-index: 100;
  letter-spacing: 2px;
  background-color: #b387c6;
  border: none;
  border-radius: 5px;
  height: 70px;
  font-size: 48px;
  position: absolute;
  top: -30px;
  right: -30px;
  box-shadow: 0 2px 2px 1px #00000040;
}

body.ios div.run-complete div.run-complete-inner div.close-screen {
  padding: 16px 12px 60px 14px;
  font-size: 64px;
}

body.windows div.run-complete div.run-complete-inner div.close-screen {
  padding: 6px 12px 16px 14px;
}

div.run-complete div.run-complete-inner div.close-screen:hover {
  color: #b387c6;
  cursor: pointer;
  background-color: #fff;
}

div.run-complete div.run-complete-inner div.close-screen:active {
  box-shadow: none;
  transform: scale(.95);
}

div.run-complete div.run-complete-inner div.run-complete-btn {
  color: #fff;
  letter-spacing: 2px;
  pointer-events: all;
  background-color: #b387c6;
  border: none;
  border-radius: 5px;
  margin-top: 0;
  margin-bottom: 25px;
  padding: 10px 18px;
  font-size: 42px;
  display: flex;
  box-shadow: 0 2px 2px 1px #00000040;
}

div.run-complete div.run-complete-inner div.run-complete-btn:hover {
  color: #b387c6;
  cursor: pointer;
  background-color: #fff;
}

div.run-complete div.run-complete-inner div.run-complete-btn:active {
  box-shadow: none;
  transform: scale(.95);
}

div.trail-select {
  pointer-events: all;
  background-color: #f2b860;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 800px;
  display: none;
  box-shadow: 0 10px 10px #00000040;
}

div.trail-select div.trail-select-inner {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

div.trail-select div.trail-select-inner div.title {
  color: #b387c6;
  text-align: center;
  letter-spacing: 2px;
  z-index: 99;
  background-color: #efd7a2;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  height: 100px;
  padding: 23px 0 30px 34px;
  font-size: 48px;
}

div.trail-select div.trail-select-inner div.close-screen {
  cursor: pointer;
  color: #fff;
  z-index: 100;
  letter-spacing: 2px;
  background-color: #b387c6;
  border: none;
  border-radius: 5px;
  height: 70px;
  font-size: 48px;
  position: absolute;
  top: -30px;
  right: -30px;
  box-shadow: 0 2px 2px 1px #00000040;
}

body.ios div.trail-select div.trail-select-inner div.close-screen {
  padding: 16px 12px 60px 14px;
  font-size: 64px;
}

body.windows div.trail-select div.trail-select-inner div.close-screen {
  padding: 6px 12px 16px 14px;
}

div.trail-select div.trail-select-inner div.close-screen:hover {
  color: #b387c6;
  cursor: pointer;
  background-color: #fff;
}

div.trail-select div.trail-select-inner div.close-screen:active {
  box-shadow: none;
  transform: scale(.95);
}

div.trail-select div.trail-select-inner div.options {
  z-index: 80;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  top: -50px;
}

div.trail-select div.trail-select-inner div.options div.trail-scroll-left-btn {
  left: 0;
}

div.trail-select div.trail-select-inner div.options div.trail-scroll-right-btn {
  right: 15px;
}

div.trail-select div.trail-select-inner div.options div.trail-scroll-left-btn, div.trail-select div.trail-select-inner div.options div.trail-scroll-right-btn {
  color: #fff;
  opacity: .75;
  cursor: pointer;
  width: 50px;
  position: absolute;
}

div.trail-select div.trail-select-inner div.options div.trail-scroll-left-btn span.material-icons, div.trail-select div.trail-select-inner div.options div.trail-scroll-right-btn span.material-icons {
  width: 50px;
  font-size: 64px !important;
}

div.trail-select div.trail-select-inner div.options div.trail-scroll-left-btn:hover, div.trail-select div.trail-select-inner div.options div.trail-scroll-right-btn:hover {
  color: #b387c6;
  cursor: pointer;
}

div.trail-select div.trail-select-inner div.options div.trail-scroll-left-btn:active, div.trail-select div.trail-select-inner div.options div.trail-scroll-right-btn:active {
  box-shadow: none;
  transform: scale(.75);
}

div.trail-select div.trail-select-inner div.options div.center-opt, div.trail-select div.trail-select-inner div.options div.left-opt, div.trail-select div.trail-select-inner div.options div.right-opt {
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

div.trail-select div.trail-select-inner div.options div.center-opt div.trail-option-name, div.trail-select div.trail-select-inner div.options div.left-opt div.trail-option-name, div.trail-select div.trail-select-inner div.options div.right-opt div.trail-option-name {
  color: #3a9393;
  letter-spacing: 2px;
  margin-bottom: 10px;
  font-size: 24px;
}

div.trail-select div.trail-select-inner div.options div.left-opt, div.trail-select div.trail-select-inner div.options div.right-opt {
  opacity: .6;
  z-index: 900;
  margin: 0 20px;
  position: relative;
  transform: scale(.65);
}

div.trail-select div.trail-select-inner div.options div.left-opt div.trail-option-name, div.trail-select div.trail-select-inner div.options div.right-opt div.trail-option-name {
  color: #fff;
}

div.trail-select div.trail-select-inner div.options div.left-opt {
  left: 30px;
}

div.trail-select div.trail-select-inner div.options div.right-opt {
  right: 30px;
}

div.trail-select div.trail-select-inner div.options div.center-opt {
  z-index: 999;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  top: 45px;
}

div.trail-select div.trail-select-inner div.options div.trail-buttons {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

div.trail-select div.trail-select-inner div.options div.trail-buttons span.trail-active {
  color: #fff;
  height: 70px;
  padding: 25px 0 10px;
  font-size: 46px;
  display: none;
}

div.trail-select div.trail-select-inner div.options div.trail-buttons button.select-trail-btn {
  justify-content: center;
  align-items: center;
  padding: 18px 10px 0 15px !important;
}

div.trail-select div.trail-select-inner div.options div.trail-buttons button.unlock-trail-btn {
  padding: 6px 9px 0;
}

div.trail-select div.trail-select-inner div.options div.trail-buttons button.choose {
  color: #fff;
  letter-spacing: 2px;
  background-color: #b387c6;
  border: none;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  height: 70px;
  font-size: 47px;
  position: relative;
  top: -10px;
  box-shadow: 0 2px 2px 1px #00000040;
}

div.trail-select div.trail-select-inner div.options div.trail-buttons button.choose:hover {
  color: #b387c6;
  cursor: pointer;
  background-color: #fff;
}

div.trail-select div.trail-select-inner div.options div.trail-buttons button.choose:active {
  box-shadow: none;
  transform: scale(.95);
}

div.trail-select div.trail-select-inner div.options div.remove {
  z-index: 80;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: -50px;
  left: 5%;
  transform: translateX(-10%);
}

div.trail-select div.trail-select-inner div.options div.remove button.remove-trail-btn {
  justify-content: center;
  align-items: center;
}

div.trail-select div.trail-select-inner div.options div.remove button.choose {
  color: #fff;
  letter-spacing: 2px;
  background-color: #b387c6;
  border: none;
  border-radius: 5px;
  height: 70px;
  font-size: 24px;
  position: relative;
  top: -10px;
  box-shadow: 0 2px 2px 1px #00000040;
}

div.trail-select div.trail-select-inner div.options div.remove button.choose:hover {
  color: #b387c6;
  cursor: pointer;
  background-color: #fff;
}

div.trail-select div.trail-select-inner div.options div.remove button.choose:active {
  box-shadow: none;
  transform: scale(.95);
}

div.restart-run-warning {
  pointer-events: all;
  background-color: #f2b860;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  display: none;
  box-shadow: 0 10px 10px #00000040;
}

div.restart-run-warning div.restart-run-warning-inner {
  text-align: center;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

div.restart-run-warning div.restart-run-warning-inner div.restart-run-title {
  color: #b387c6;
  text-align: center;
  letter-spacing: 2px;
  z-index: 99;
  background-color: #efd7a2;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  height: 100px;
  padding: 23px 0 30px 34px;
  font-size: 48px;
}

div.restart-run-warning div.restart-run-warning-inner div.close-screen {
  cursor: pointer;
  color: #fff;
  z-index: 100;
  letter-spacing: 2px;
  background-color: #b387c6;
  border: none;
  border-radius: 5px;
  height: 70px;
  font-size: 48px;
  position: absolute;
  top: -30px;
  right: -30px;
  box-shadow: 0 2px 2px 1px #00000040;
}

body.ios div.restart-run-warning div.restart-run-warning-inner div.close-screen {
  padding: 16px 12px 60px 14px;
  font-size: 64px;
}

body.windows div.restart-run-warning div.restart-run-warning-inner div.close-screen {
  padding: 6px 12px 16px 14px;
}

div.restart-run-warning div.restart-run-warning-inner div.close-screen:hover {
  color: #b387c6;
  cursor: pointer;
  background-color: #fff;
}

div.restart-run-warning div.restart-run-warning-inner div.close-screen:active {
  box-shadow: none;
  transform: scale(.95);
}

div.restart-run-warning div.restart-run-warning-inner p.warning {
  color: #fff;
  padding: 0 30px;
  font-size: 32px;
}

div.restart-run-warning div.restart-run-warning-inner button.restart-run-btn-confirm {
  color: #fff;
  letter-spacing: 2px;
  background-color: #b387c6;
  border: none;
  border-radius: 5px;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 24px 12px 8px;
  font-size: 47px;
  box-shadow: 0 2px 2px 1px #00000040;
}

div.restart-run-warning div.restart-run-warning-inner button.restart-run-btn-confirm:hover {
  color: #b387c6;
  cursor: pointer;
  background-color: #fff;
}

div.restart-run-warning div.restart-run-warning-inner button.restart-run-btn-confirm:active {
  box-shadow: none;
  transform: scale(.95);
}

div.view-settings {
  pointer-events: all;
  background-color: #f2b860;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  display: none;
  box-shadow: 0 10px 10px #00000040;
}

div.view-settings div.view-settings-inner {
  text-align: center;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

div.view-settings div.view-settings-inner div.view-settings-title {
  color: #b387c6;
  text-align: center;
  letter-spacing: 2px;
  z-index: 99;
  background-color: #efd7a2;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  height: 100px;
  padding: 23px 0 30px 34px;
  font-size: 48px;
}

div.view-settings div.view-settings-inner h3.settings-header {
  color: #b387c6;
  font-size: 36px;
}

div.view-settings div.view-settings-inner div.close-screen {
  cursor: pointer;
  color: #fff;
  z-index: 100;
  letter-spacing: 2px;
  background-color: #b387c6;
  border: none;
  border-radius: 5px;
  height: 70px;
  font-size: 48px;
  position: absolute;
  top: -30px;
  right: -30px;
  box-shadow: 0 2px 2px 1px #00000040;
}

body.ios div.view-settings div.view-settings-inner div.close-screen {
  padding: 16px 12px 60px 14px;
  font-size: 64px;
}

body.windows div.view-settings div.view-settings-inner div.close-screen {
  padding: 6px 12px 16px 14px;
}

div.view-settings div.view-settings-inner div.close-screen:hover {
  color: #b387c6;
  cursor: pointer;
  background-color: #fff;
}

div.view-settings div.view-settings-inner div.close-screen:active {
  box-shadow: none;
  transform: scale(.95);
}

div.view-settings div.view-settings-inner p.privacy-policy {
  color: #fff;
  padding: 0 30px;
  font-size: 32px;
}

.shadow-parent {
  justify-content: center;
  align-items: center;
  margin: 10px auto auto;
}

.bacon-reward {
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
}

.discord-container {
  margin-top: -20px;
  margin-bottom: 20px;
  animation: .4s ease-in-out infinite alternate wiggle;
  position: relative;
}

@keyframes wiggle {
  0% {
    transform: rotate(-2deg);
  }

  100% {
    transform: rotate(2deg);
  }
}

.discord-container:hover {
  transform: scale(1.05);
}

.discord-container:active {
  transform: scale(.9);
}

.shadow-btn {
  text-align: center;
  cursor: pointer;
  background-color: #efd7a2;
  border: 4px solid #fff;
  border-radius: .5rem;
  margin: auto;
  padding: 1rem;
  transition: transform .1s ease-in-out;
  box-shadow: 0 4px 6px #0000001a;
}

.shadow-btn img {
  height: 50px;
  margin: auto;
}

.shadow-btn p {
  margin-bottom: 0;
}

div.character-select {
  pointer-events: all;
  background-color: #f2b860;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 800px;
  display: none;
  box-shadow: 0 10px 10px #00000040;
}

div.character-select div.character-select-inner {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

div.character-select div.character-select-inner div.title {
  color: #b387c6;
  text-align: center;
  letter-spacing: 2px;
  z-index: 99;
  background-color: #efd7a2;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  height: 100px;
  padding: 23px 0 30px 34px;
  font-size: 48px;
}

div.character-select div.character-select-inner div.close-screen {
  cursor: pointer;
  color: #fff;
  z-index: 100;
  letter-spacing: 2px;
  background-color: #b387c6;
  border: none;
  border-radius: 5px;
  height: 70px;
  font-size: 48px;
  position: absolute;
  top: -30px;
  right: -30px;
  box-shadow: 0 2px 2px 1px #00000040;
}

body.ios div.character-select div.character-select-inner div.close-screen {
  padding: 16px 12px 60px 14px;
  font-size: 64px;
}

body.windows div.character-select div.character-select-inner div.close-screen {
  padding: 6px 12px 16px 14px;
}

div.character-select div.character-select-inner div.close-screen:hover {
  color: #b387c6;
  cursor: pointer;
  background-color: #fff;
}

div.character-select div.character-select-inner div.close-screen:active {
  box-shadow: none;
  transform: scale(.95);
}

div.character-select div.character-select-inner div.options {
  z-index: 80;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  top: -50px;
}

div.character-select div.character-select-inner div.options div.character-scroll-left-btn {
  left: 0;
}

div.character-select div.character-select-inner div.options div.character-scroll-right-btn {
  right: 15px;
}

div.character-select div.character-select-inner div.options div.character-scroll-left-btn, div.character-select div.character-select-inner div.options div.character-scroll-right-btn {
  color: #fff;
  opacity: .75;
  cursor: pointer;
  width: 50px;
  position: absolute;
}

div.character-select div.character-select-inner div.options div.character-scroll-left-btn span.material-icons, div.character-select div.character-select-inner div.options div.character-scroll-right-btn span.material-icons {
  width: 50px;
  font-size: 64px !important;
}

div.character-select div.character-select-inner div.options div.character-scroll-left-btn:hover, div.character-select div.character-select-inner div.options div.character-scroll-right-btn:hover {
  color: #b387c6;
  cursor: pointer;
}

div.character-select div.character-select-inner div.options div.character-scroll-left-btn:active, div.character-select div.character-select-inner div.options div.character-scroll-right-btn:active {
  box-shadow: none;
  transform: scale(.75);
}

div.character-select div.character-select-inner div.options div.center-opt, div.character-select div.character-select-inner div.options div.left-opt, div.character-select div.character-select-inner div.options div.right-opt {
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

div.character-select div.character-select-inner div.options div.center-opt div.character-option-name, div.character-select div.character-select-inner div.options div.left-opt div.character-option-name, div.character-select div.character-select-inner div.options div.right-opt div.character-option-name {
  color: #3a9393;
  letter-spacing: 2px;
  margin-bottom: 10px;
  font-size: 24px;
}

div.character-select div.character-select-inner div.options div.left-opt, div.character-select div.character-select-inner div.options div.right-opt {
  opacity: .6;
  z-index: 900;
  margin: 0 20px;
  position: relative;
  top: -30px;
  transform: scale(.65);
}

div.character-select div.character-select-inner div.options div.left-opt div.character-option-name, div.character-select div.character-select-inner div.options div.right-opt div.character-option-name {
  color: #fff;
}

div.character-select div.character-select-inner div.options div.center-opt {
  z-index: 999;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  transform: scale(1.25);
}

div.character-select div.character-select-inner div.options div.center-opt span.character-active {
  color: #fff;
  padding: 70px 0 10px;
  font-size: 46px;
  display: none;
  position: relative;
  top: -15px;
}

div.character-select div.character-select-inner div.options div.center-opt button.select-character-btn {
  justify-content: center;
  align-items: center;
  padding: 15px 10px 0 15px !important;
}

div.character-select div.character-select-inner div.options div.center-opt button.unlock-character-btn, div.character-select div.character-select-inner div.options div.center-opt button.unlock-discord-btn {
  padding-top: 5px;
}

div.character-select div.character-select-inner div.options div.center-opt button.choose {
  color: #fff;
  letter-spacing: 2px;
  background-color: #b387c6;
  border: none;
  border-radius: 5px;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 8px 12px;
  font-size: 47px;
  box-shadow: 0 2px 2px 1px #00000040;
}

div.character-select div.character-select-inner div.options div.center-opt button.choose:hover {
  color: #b387c6;
  cursor: pointer;
  background-color: #fff;
}

div.character-select div.character-select-inner div.options div.center-opt button.choose:active {
  box-shadow: none;
  transform: scale(.95);
}

.ui-pop-loader {
  text-align: center;
  background-color: #52473d;
  border: 3px solid #fff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 60px;
  display: none;
  position: absolute;
  bottom: 60px;
  transform: scale(1.5);
}

.ui-pop-loader .ui-pop-loader-inner {
  width: 100%;
  height: 100%;
  position: relative;
}

.ui-pop-loader .ui-pop-loader-inner .ui-pop-loader-text {
  color: #fff;
  z-index: 100;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
  font-size: 40px;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
}

.ui-pop-loader .ui-pop-loader-inner .ui-pop-loader-progress {
  z-index: 80;
  background-color: #6f6053;
  border-radius: 8px;
  width: 25%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.discord-info {
  margin: 8px auto auto 8px;
  padding: 0;
}

.unlock-discord-btn {
  padding: 10px !important;
}

.hud-ad-reward {
  transform-origin: 0;
  align-self: flex-start;
  margin: 20px;
}

.container {
  z-index: 10;
  cursor: pointer;
  pointer-events: auto;
  position: relative;
}

.frame {
  pointer-events: none;
  width: auto;
  height: 8rem;
}

.title {
  pointer-events: none;
  text-transform: uppercase;
  white-space: normal;
  color: #000;
  text-shadow: -2px -2px #fff, 2px -2px #fff, -2px 2px #fff, 2px 2px #fff, -3px 0 #fff, 3px 0 #fff, 0 -3px #fff, 0 3px #fff;
  width: 2.5rem;
  margin-left: 1rem;
  font-family: YourFontHere, sans-serif;
  font-size: 2.25rem;
  font-weight: bold;
  line-height: 2rem;
  position: absolute;
  top: 0;
  transform: rotate(-6deg)translateY(-12.5%);
}

.ad-icon {
  pointer-events: none;
  height: 3rem;
  margin-bottom: 1.25rem;
  margin-left: 1.25rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.character {
  pointer-events: none;
  height: 10rem;
  position: absolute;
  bottom: 1.5rem;
  right: 2.5rem;
  transform: rotate(-6deg)translateX(25%)translateY(25%);
}

.close-button {
  cursor: pointer;
  background-color: #ff4d4d;
  border: 2px solid #000;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  padding: 15px;
  transition: all .2s ease-in-out;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-25%)translateX(25%);
}

.close-button:hover {
  background-color: #f66;
}

.close-icon {
  height: 1.5rem;
}

@keyframes wiggle-light {
  0%, 100% {
    transform: rotate(-2deg);
  }

  50% {
    transform: rotate(2deg);
  }
}

.animate-wiggle-light {
  animation: 1s infinite wiggle-light;
}

.mobile .mobile-scale-60 {
  margin-top: -20px;
  transform: scale(.6);
}

:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animate__animated {
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

.animate__animated.animate__infinite {
  animation-iteration-count: infinite;
}

.animate__animated.animate__repeat-1 {
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: var(--animate-repeat);
  animation-iteration-count: var(--animate-repeat);
}

.animate__animated.animate__repeat-2 {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 2);
  animation-iteration-count: calc(var(--animate-repeat) * 2);
}

.animate__animated.animate__repeat-3 {
  animation-iteration-count: 3;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 3);
  animation-iteration-count: calc(var(--animate-repeat) * 3);
}

.animate__animated.animate__delay-1s {
  animation-delay: 1s;
  -webkit-animation-delay: var(--animate-delay);
  animation-delay: var(--animate-delay);
}

.animate__animated.animate__delay-2s {
  animation-delay: 2s;
  -webkit-animation-delay: calc(var(--animate-delay) * 2);
  animation-delay: calc(var(--animate-delay) * 2);
}

.animate__animated.animate__delay-3s {
  animation-delay: 3s;
  -webkit-animation-delay: calc(var(--animate-delay) * 3);
  animation-delay: calc(var(--animate-delay) * 3);
}

.animate__animated.animate__delay-4s {
  animation-delay: 4s;
  -webkit-animation-delay: calc(var(--animate-delay) * 4);
  animation-delay: calc(var(--animate-delay) * 4);
}

.animate__animated.animate__delay-5s {
  animation-delay: 5s;
  -webkit-animation-delay: calc(var(--animate-delay) * 5);
  animation-delay: calc(var(--animate-delay) * 5);
}

.animate__animated.animate__faster {
  animation-duration: .5s;
  -webkit-animation-duration: calc(var(--animate-duration) / 2);
  animation-duration: calc(var(--animate-duration) / 2);
}

.animate__animated.animate__fast {
  animation-duration: .8s;
  -webkit-animation-duration: calc(var(--animate-duration) * .8);
  animation-duration: calc(var(--animate-duration) * .8);
}

.animate__animated.animate__slow {
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
}

.animate__animated.animate__slower {
  animation-duration: 3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 3);
  animation-duration: calc(var(--animate-duration) * 3);
}

@media print, (prefers-reduced-motion: reduce) {
  .animate__animated {
    transition-duration: 1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }

  .animate__animated[class*="Out"] {
    opacity: 0;
  }
}

@keyframes bounce {
  from, 20%, 53%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%, 43% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -30px, 0)scaleY(1.1);
  }

  70% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -15px, 0)scaleY(1.05);
  }

  80% {
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -4px, 0)scaleY(1.02);
  }
}

.animate__bounce {
  transform-origin: bottom;
  animation-name: bounce;
}

@keyframes flash {
  from, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.animate__flash {
  animation-name: flash;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__pulse {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, .75, 1);
  }

  40% {
    transform: scale3d(.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, .85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__rubberBand {
  animation-name: rubberBand;
}

@keyframes shakeX {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.animate__shakeX {
  animation-name: shakeX;
}

@keyframes shakeY {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(0, -10px, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(0, 10px, 0);
  }
}

.animate__shakeY {
  animation-name: shakeY;
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px)rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px)rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px)rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px)rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.animate__headShake {
  animation-name: headShake;
  animation-timing-function: ease-in-out;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0);
  }
}

.animate__swing {
  transform-origin: top;
  animation-name: swing;
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    transform: scale3d(.9, .9, .9)rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__tada {
  animation-name: tada;
}

@keyframes wobble {
  from {
    transform: translate3d(0, 0, 0);
  }

  15% {
    transform: translate3d(-25%, 0, 0)rotate3d(0, 0, 1, -5deg);
  }

  30% {
    transform: translate3d(20%, 0, 0)rotate3d(0, 0, 1, 3deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0)rotate3d(0, 0, 1, -3deg);
  }

  60% {
    transform: translate3d(10%, 0, 0)rotate3d(0, 0, 1, 2deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0)rotate3d(0, 0, 1, -1deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__wobble {
  animation-name: wobble;
}

@keyframes jello {
  from, 11.1%, to {
    transform: translate3d(0, 0, 0);
  }

  22.2% {
    transform: skewX(-12.5deg)skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg)skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg)skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg)skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-.78125deg)skewY(-.78125deg);
  }

  77.7% {
    transform: skewX(.390625deg)skewY(.390625deg);
  }

  88.8% {
    transform: skewX(-.195313deg)skewY(-.195313deg);
  }
}

.animate__jello {
  transform-origin: center;
  animation-name: jello;
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.3);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1);
  }
}

.animate__heartBeat {
  animation-name: heartBeat;
  animation-duration: 1.3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 1.3);
  animation-duration: calc(var(--animate-duration) * 1.3);
  animation-timing-function: ease-in-out;
}

@keyframes backInDown {
  0% {
    opacity: .7;
    transform: translateY(-1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInDown {
  animation-name: backInDown;
}

@keyframes backInLeft {
  0% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInLeft {
  animation-name: backInLeft;
}

@keyframes backInRight {
  0% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInRight {
  animation-name: backInRight;
}

@keyframes backInUp {
  0% {
    opacity: .7;
    transform: translateY(1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInUp {
  animation-name: backInUp;
}

@keyframes backOutDown {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateY(700px)scale(.7);
  }
}

.animate__backOutDown {
  animation-name: backOutDown;
}

@keyframes backOutLeft {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }
}

.animate__backOutLeft {
  animation-name: backOutLeft;
}

@keyframes backOutRight {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }
}

.animate__backOutRight {
  animation-name: backOutRight;
}

@keyframes backOutUp {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateY(-700px)scale(.7);
  }
}

.animate__backOutUp {
  animation-name: backOutUp;
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.animate__bounceIn {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: bounceIn;
}

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0)scaleY(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, -10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, 5px, 0)scaleY(.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(-10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(-5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0)scaleY(5);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, 10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -5px, 0)scaleY(.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

.animate__bounceOut {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: bounceOut;
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0)scaleY(3);
  }
}

.animate__bounceOutDown {
  animation-name: bounceOutDown;
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0)scaleX(2);
  }
}

.animate__bounceOutLeft {
  animation-name: bounceOutLeft;
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0)scaleX(2);
  }
}

.animate__bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0)scaleY(3);
  }
}

.animate__bounceOutUp {
  animation-name: bounceOutUp;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate__fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeInTopLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInTopLeft {
  animation-name: fadeInTopLeft;
}

@keyframes fadeInTopRight {
  from {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInTopRight {
  animation-name: fadeInTopRight;
}

@keyframes fadeInBottomLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInBottomLeft {
  animation-name: fadeInBottomLeft;
}

@keyframes fadeInBottomRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInBottomRight {
  animation-name: fadeInBottomRight;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.animate__fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.animate__fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.animate__fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.animate__fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.animate__fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.animate__fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.animate__fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.animate__fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@keyframes fadeOutTopLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }
}

.animate__fadeOutTopLeft {
  animation-name: fadeOutTopLeft;
}

@keyframes fadeOutTopRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }
}

.animate__fadeOutTopRight {
  animation-name: fadeOutTopRight;
}

@keyframes fadeOutBottomRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }
}

.animate__fadeOutBottomRight {
  animation-name: fadeOutBottomRight;
}

@keyframes fadeOutBottomLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }
}

.animate__fadeOutBottomLeft {
  animation-name: fadeOutBottomLeft;
}

@keyframes flip {
  from {
    animation-timing-function: ease-out;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 0)rotate3d(0, 1, 0, -360deg);
  }

  40% {
    animation-timing-function: ease-out;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -190deg);
  }

  50% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -170deg);
  }

  80% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(.95, .95, .95)translate3d(0, 0, 0)rotate3d(0, 1, 0, 0);
  }

  to {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 0)rotate3d(0, 1, 0, 0);
  }
}

.animate__animated.animate__flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  animation-name: flip;
}

@keyframes flipInX {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.animate__flipInX {
  animation-name: flipInX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes flipInY {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(0, 1, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.animate__flipInY {
  animation-name: flipInY;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes flipOutX {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.animate__flipOutX {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes flipOutY {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, -15deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }
}

.animate__flipOutY {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: flipOutY;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes lightSpeedInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(-30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(20deg);
  }

  80% {
    transform: skewX(-5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__lightSpeedInRight {
  animation-name: lightSpeedInRight;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(-20deg);
  }

  80% {
    transform: skewX(5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__lightSpeedInLeft {
  animation-name: lightSpeedInLeft;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(30deg);
  }
}

.animate__lightSpeedOutRight {
  animation-name: lightSpeedOutRight;
  animation-timing-function: ease-in;
}

@keyframes lightSpeedOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(-30deg);
  }
}

.animate__lightSpeedOutLeft {
  animation-name: lightSpeedOutLeft;
  animation-timing-function: ease-in;
}

@keyframes rotateIn {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -200deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateIn {
  transform-origin: center;
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -90deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 200deg);
  }
}

.animate__rotateOut {
  transform-origin: center;
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }
}

.animate__rotateOutDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.animate__rotateOutDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.animate__rotateOutUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 90deg);
  }
}

.animate__rotateOutUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutUpRight;
}

@keyframes hinge {
  0% {
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 80deg);
  }

  40%, 80% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 60deg);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 700px, 0);
  }
}

.animate__hinge {
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
  transform-origin: 0 0;
  animation-name: hinge;
}

@keyframes jackInTheBox {
  from {
    opacity: 0;
    transform-origin: bottom;
    transform: scale(.1)rotate(30deg);
  }

  50% {
    transform: rotate(-10deg);
  }

  70% {
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__jackInTheBox {
  animation-name: jackInTheBox;
}

@keyframes rollIn {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)rotate3d(0, 0, 1, -120deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rollIn {
  animation-name: rollIn;
}

@keyframes rollOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)rotate3d(0, 0, 1, 120deg);
  }
}

.animate__rollOut {
  animation-name: rollOut;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.animate__zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, -1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }
}

.animate__zoomInDown {
  animation-name: zoomInDown;
}

@keyframes zoomInLeft {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(-1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(10px, 0, 0);
  }
}

.animate__zoomInLeft {
  animation-name: zoomInLeft;
}

@keyframes zoomInRight {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(-10px, 0, 0);
  }
}

.animate__zoomInRight {
  animation-name: zoomInRight;
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }
}

.animate__zoomInUp {
  animation-name: zoomInUp;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.animate__zoomOut {
  animation-name: zoomOut;
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, 2000px, 0);
  }
}

.animate__zoomOutDown {
  transform-origin: bottom;
  animation-name: zoomOutDown;
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(-2000px, 0, 0);
  }
}

.animate__zoomOutLeft {
  transform-origin: 0;
  animation-name: zoomOutLeft;
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(2000px, 0, 0);
  }
}

.animate__zoomOutRight {
  transform-origin: 100%;
  animation-name: zoomOutRight;
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, -2000px, 0);
  }
}

.animate__zoomOutUp {
  transform-origin: bottom;
  animation-name: zoomOutUp;
}

@keyframes slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  from {
    visibility: visible;
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.animate__slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.animate__slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

.animate__slideOutUp {
  animation-name: slideOutUp;
}

/*# sourceMappingURL=index.3c2546b8.css.map */
