@import "./responsiveness.less";
@import "./google-fonts.less";

// #region global styles

/*****************************************************/
/*                                                   */
/*              Global Styles                        */
/*                                                   */
/*****************************************************/
@font-face {
    font-family: "Luckiest Guy";
    src: url("ui/fonts/LuckiestGuy-Regular.ttf") format("truetype");
}

img.hidden-preload {
    display: none !important;
}

img {
    user-select: none;
}

* {
    box-sizing: border-box;
    user-select: none;
    font-family: "Luckiest Guy", sans-serif;
}

html,
body {
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

option {
    color: black !important;
}

body {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #282828;
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-zoom: none;
}

#stats {
    display: none;
    position: absolute;
    align-items: center;
    justify-content: center;
    bottom: 0;
}

#stats canvas {
    display: flex !important;
}

#game-container {
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 0;
    background-color: #000;
    position: relative;
    overflow: hidden;
}

#ui-root {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    pointer-events: none;
    // border: 3px solid red;
}

.screen-router {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
// #endregion

// #region Animations
/*****************************************************/
/*                                                   */
/*                     Animations                    */
/*                                                   */
/*****************************************************/

@keyframes loading-bar-animation {
    0% {
        mask-size: 0% 300%;
        -webkit-mask-size: 0% 300%;
    }
    100% {
        mask-size: 100% 300%;
        -webkit-mask-size: 100% 300%;
    }
}
// #endregion

// #region Loading / Splash
div.loading-splash {
    z-index: 10;
    height: 100%;
    width: 100%;
    position: fixed;
    background-image: url("../../static/ui/loading_splash/UpTogether_SplashBackground.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    div.error-screen {
        display: none;

        h3.error-msg {
            margin: 0;
            padding: 0 30px;
            text-align: center;
            text-shadow: 0 0 5px black;
            line-height: 45px;
            font-size: 36px;
            color: white;
            letter-spacing: 1px;
            max-width: 400px;

            &.error {
                color: red;
            }
        }
    }

    img.game-logo {
        // border: 1px solid red;
    }

    .smg-logo {
        position: absolute;
        align-self: flex-end;
        bottom: 0;
        text-align: center;
        color: #e6b23e;
        // subtle text shadow
        text-shadow: 0 0 3px black;

        img {
            position: relative;
            top: 20px;
            height: 100px;
        }
    }

    div.loading {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        div.loading-bar {
            height: 128px;
            width: 512px;
            position: relative;
            overflow: hidden;

            // border: 1px solid red;

            img.loading-bar-bg {
                position: absolute;
                height: 100%;
                width: 100%;
            }
            img.loading-bar-fg {
                position: absolute;
                height: 100%;
                width: 100%;

                mask-image: linear-gradient(to bottom, transparent, black, transparent);
                -webkit-mask-image: linear-gradient(to bottom, transparent, black, transparent);

                mask-size: 0% 300%;
                -webkit-mask-size: 0% 300%;

                mask-repeat: no-repeat;
                -webkit-mask-repeat: no-repeat;

                mask-position: left center;
                -webkit-mask-position: left center;
                // animation: none;
                animation: all 300ms ease;
                // animation: loading-bar-animation 5000ms infinite linear;
            }
        }

        div.loading-text {
            // border: 1px solid red;
            text-align: center;
            font-size: 24px;
            color: white;
            margin-top: 16px;
            letter-spacing: 2px;
            text-shadow: 0 0 4px black;
            max-width: 450px;
            height: 60px;
        }
    }
}
// #endregion

// #region UI Screen Open Overlay
.ui-screen-open-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.25);
    pointer-events: none;
    z-index: 1;
    display: none;
}
// #endregion

// #region HUD
div.hud {
    touch-action: manipulation;
    z-index: 500;
    width: 100%;
    height: 100%;
    display: none;
    pointer-events: none;
    // display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .joystick-container {
        pointer-events: all !important;
        position: absolute;
        bottom: 20px;
        left: 20px;
        height: 200px;
        width: 200px;
        // border: 2px solid red;

        .joystick-container-inner {
            position: relative;
            height: 100%;
            width: 100%;
        }
    }

    .jump-button-container {
        display: none;
        position: absolute;
        // bottom: 20px;
        // right: 20px;
        bottom: 85px;
        right: 35px;

        .jump-button {
            font-size: 42px;
            color: white;
            padding: 10px 18px;
            border-radius: 5px;
            letter-spacing: 2px;
            box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.25);
            border: none;
            background-color: #b387c6;
            display: flex;
            pointer-events: all;
            transform: scale(1);
            z-index: 5 !important;
            // height: 55px;
            padding: 20px 20px 7px 20px;

            &:hover {
                color: #b387c6;
                background-color: white;
                cursor: pointer;
            }

            &:active {
                transform: scale(0.95);
                box-shadow: none;
            }
        }
    }

    p.alert {
        display: none;
        pointer-events: none;
        // border: 1px solid red;
        margin-top: 8%;
        padding: 0;
        color: white;
        font-size: 90px;
        text-shadow: 0 0 10px black;
        padding-top: 30px;
        text-align: center;
    }

    div.play-btn {
        margin-top: 25px;
        font-size: 42px;
        color: white;
        border-radius: 5px;
        letter-spacing: 2px;
        margin-bottom: 25px;
        box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.25);
        border: none;
        background-color: #b387c6;
        display: flex;
        position: fixed;
        bottom: 250px;
        pointer-events: all;
        transform: scale(3);
        z-index: 5 !important;
        bottom: 100px;
        transform: translate3d(0, 0, 0);

        body.ios & {
            padding: 12px 14px 0px 18px;
        }

        body.windows & {
            padding: 0px 14px 0px 18px;
        }

        &:hover {
            color: #b387c6;
            background-color: white;
            cursor: pointer;
        }

        &:active {
            transform: scale(2.75);
            box-shadow: none;
        }
    }

    div.top-center-hud {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        flex: none;
        margin-top: 20px;

        .run-duration {
            flex: 1 1 auto;
        }

        div.height,
        div.falls {
            // top: 0;
            height: 43px; //hacky fix for consistent height
            text-shadow: 0 0 5px black;
            color: white;
            font-size: 24px;
            top: 30px;
            flex: none;

            span.material-icons {
                position: relative;
                // top: 7px !important;
                left: -8px;
            }

            span {
                position: relative;
                font-size: 36px;
            }
        }

        .height-metric {
            font-size: unset !important;
            top: -3px;
        }

        div.falls {
            top: 25px;
            left: -100px;

            .material-icons {
                top: -6px;
            }
        }

        div.height {
            top: 35px;
            left: 250px;
        }

        .falls-value {
            vertical-align: top;
        }

        div.run-duration {
            height: 100%;
            width: 240px;
            text-align: center;
            color: white;
            font-size: 72px;
            text-shadow: 0 0 10px black;

            &.complete {
                animation: colorTransition 8s linear infinite;
            }
        }
    }
}
// #endregion

// #region Emoji Selector
div.emoji-selector {
    height: 100px;
    width: 100px;
    // border: 2px solid red;
    // overflow: hidden;
    pointer-events: none;
    display: none;

    div.close-screen {
        pointer-events: all;
        position: absolute;
        top: -200px;
        right: -300px;

        font-size: 48px; /* Default font size */
        cursor: pointer;
        color: white;
        z-index: 100;
        border-radius: 5px;
        letter-spacing: 2px;
        height: 70px;
        box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.25);
        border: none;
        background-color: #b387c6;

        body.ios & {
            font-size: 64px;
            padding: 16px 12px 60px 14px;
        }

        body.windows & {
            padding: 6px 12px 16px 14px;
        }

        &:hover {
            color: #b387c6;
            background-color: white;
            cursor: pointer;
        }

        &:active {
            transform: scale(0.95);
            box-shadow: none;
        }
    }

    div.emoji-selector-inner {
        position: relative;
        height: 100%;
        width: 100%;
        pointer-events: none;

        img {
            z-index: 999;
            pointer-events: all;
            transform: scale(0.5);
            position: absolute;
            top: -500px;
            left: -500px;
            cursor: pointer;
            transition: all 150ms ease;

            &:hover {
                transform: scale(0.6);
            }

            &.apple {
                top: -75px;
                left: -75px;
            }

            &.baguette {
                top: -150px;
                left: -145px;
            }

            &.blueberry {
                top: 15px;
                left: 15px;
            }

            &.broccoli {
                top: 55px;
                left: -115px;
            }

            &.hat {
                top: -115px;
                left: 45px;
            }

            &.come {
                top: -200px;
                left: -55px;
            }

            &.cupcake {
                top: -95px;
                left: -305px;
            }

            &.eggplant {
                top: -175px;
                left: -235px;
            }

            &.fly {
                top: 35px;
                left: -265px;
            }

            &.icecream {
                top: -55px;
                left: 150px;
            }

            &.invis {
                top: 55px;
                left: 115px;
            }

            &.peach {
                top: -175px;
                left: 165px;
            }

            &.spatula {
                top: -45px;
                left: -205px;
            }
        }
    }
}
// #endregion

// #region UI Screen Parent

div.ui-screen-parent {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    border: 50px #000;
}
// #endregion

// #region Change Username
div.change-username {
    display: none;
    // border: 3px solid white;
    border-radius: 8px;
    // height: 525px;
    width: 600px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f2b860;
    pointer-events: all;
    transform: scale(1.3);
    box-shadow: 0px 10px 10px 0px #00000040;

    div.change-username-inner {
        position: relative;
        display: center;
        height: 100%;
        width: 100%;
        // display: flex;

        div.title {
            padding: 23px 0 30px 34px;
            height: 100px;
            width: 100%;
            color: white;
            font-size: 48px;
            text-align: center;
            color: #b387c6;
            background-color: #efd7a2;
            letter-spacing: 2px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            z-index: 99;
        }

        div.input-container {
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            div.input {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;

                img.flag-preview {
                    position: absolute;
                    transform: scale(0.75);

                    &.left {
                        left: -75px;
                        top: 39px;
                    }

                    &.right {
                        right: -75px;
                        top: 39px;
                    }
                }

                &:nth-of-type(2) {
                    margin-top: 20px;
                }

                h3 {
                    text-shadow: 0 0 5px black;
                    color: white;
                    padding: 0;
                    margin: 0;
                    font-size: 36px;
                }

                select {
                    height: 50px;
                    border: 0;
                    padding: 10px 0 0 10px;
                    font-size: 36px;
                    margin-bottom: 10px;
                    width: 300px;
                    border-radius: 5px;
                    color: white;
                    background-color: #b387c6;
                    text-align: center;

                    &:nth-of-type(2),
                    &:nth-of-type(3) {
                        text-align: center;
                    }
                }
            }
        }

        div.button-container {
            text-align: center;
        }

        button.change-username-btn {
            margin-top: 25px;
            font-size: 42px;
            color: white;
            padding: 13px 12px 10px 13px;
            border-radius: 5px;
            letter-spacing: 2px;
            margin-bottom: 25px;
            box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.25);
            border: none;
            background-color: #b387c6;

            &:hover {
                color: #b387c6;
                background-color: white;
                cursor: pointer;
            }

            &:active {
                transform: scale(0.95);
                box-shadow: none;
            }
        }

        div.close-screen {
            position: absolute;
            top: -30px;
            right: -30px;

            font-size: 48px; /* Default font size */
            cursor: pointer;
            color: white;
            z-index: 100;
            border-radius: 5px;
            letter-spacing: 2px;
            height: 70px;
            box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.25);
            border: none;
            background-color: #b387c6;

            body.ios & {
                font-size: 64px;
                padding: 16px 12px 60px 14px;
            }

            body.windows & {
                padding: 6px 12px 16px 14px;
            }

            &:hover {
                color: #b387c6;
                background-color: white;
                cursor: pointer;
            }

            &:active {
                transform: scale(0.95);
                box-shadow: none;
            }
        }
    }
}
// #endregion

.top-ui-container {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    align-items: center;
    gap: 10px;
    flex-wrap: wrap;

    > * {
        flex-grow: 1;
        transform: none !important;
        flex-shrink: 0;
        flex: none;

        &:last-child {
            width: 360px;
        }

        &.top-center-hud {
            width: auto;
        }
    }
}

// #region Top Left HUD
div.top-left-hud {
    // top: 20px;
    // left: 40px;
    // display: flex;
    // flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;

    span.keyboard-keypress {
        font-size: 20px !important;
        // text-shadow: 0 0 5px black;
        position: relative;
        left: 8px;
        top: 2px;
        color: white !important;

        &.esc {
            left: -1px;
        }

        &.f {
            left: 8px;
        }

        &.p {
            left: 8px;
        }

        &.r {
            top: -3px;
            left: 7px;
        }

        &.f10 {
            left: -4px;
        }

        &.m {
            left: 4px;
        }
    }

    div.restart-cp-btn {
        margin-right: 10px;
        pointer-events: all;
        height: 50px;
        width: 50px;
        border: 3px solid white;
        border-radius: 5px;
        position: relative;
        // opacity: 0.8;
        cursor: pointer;
        // Subtle black box shadow around the full outside
        // box-shadow: 0 0 5px 1px black;
        background-color: #efd7a2;
        display: none;

        img {
            position: relative;
            top: -4px;
            left: 5px;
            height: 34px;
            width: 34px;
        }

        body.ios & {
            padding-top: 9px;
        }

        &:hover {
            transform: scale(1.1);
            opacity: 1;
        }

        &:active {
            transform: scale(0.75);
        }

        span {
            color: #b387c6;
            font-size: 45px;
        }
    }

    div.mute-music-btn,
    div.mute-sfx-btn,
    div.restart-run-btn,
    div.fs-btn,
    div.disc-btn,
    div.emoji-btn,
    div.settings-btn {
        margin-right: 10px;
        pointer-events: all;
        height: 50px;
        width: 50px;
        border: 3px solid white;
        border-radius: 5px;
        position: relative;
        // opacity: 0.8;
        cursor: pointer;
        // Subtle black box shadow around the full outside
        // box-shadow: 0 0 5px 1px black;
        background-color: #efd7a2;

        img {
            position: relative;
            top: -4px;
            left: 5px;
            height: 34px;
            width: 34px;
        }

        &.settings-btn {
        }

        &.restart-run-btn {
            display: none;
        }

        &:hover {
            transform: scale(1.1);
            opacity: 1;
        }

        &:active {
            transform: scale(0.75);
        }

        span {
            color: #b387c6;
            font-size: 45px;
        }

        div.off {
            position: absolute;
            border-bottom: 4px solid white;
            display: flex;
            // border-radius: 3px;
            height: 10px;
            z-index: 999;
            width: 63px;
            transform: rotate(-45deg);
            top: 15px;
            left: -12px;

            &.shown {
                display: flex;
            }
        }
    }
}
// #endregion

// #region Run Complete
@keyframes colorTransition {
    0% {
        color: red;
    }
    14% {
        color: orange;
    }
    28% {
        color: yellow;
    }
    42% {
        color: green;
    }
    57% {
        color: blue;
    }
    71% {
        color: indigo;
    }
    85% {
        color: violet;
    }
    100% {
        color: red;
    }
}

div.run-complete {
    display: none;
    border-radius: 8px;
    width: 500px;
    // height: 420px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f2b860;
    pointer-events: all;
    box-shadow: 0px 10px 10px 0px #00000040;
    transform: scale(1.3);

    div.run-complete-inner {
        position: relative;
        display: block;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        div.title {
            padding: 23px 0 30px 34px;
            height: 100px;
            width: 100%;
            color: white;
            font-size: 48px;
            text-align: center;
            color: #b387c6;
            background-color: #efd7a2;
            letter-spacing: 2px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            z-index: 99;
        }

        div.run-details {
            margin: 50px 0 20px 0;
            div.your-time-title {
                text-align: center;
                color: #fff;
                font-size: 51px;
                margin-bottom: 10px;
            }

            h2.your-time {
                padding-top: 25px;
                text-align: center;
                color: #fff;
                font-size: 96px;
                margin: 0;
                animation: colorTransition 8s linear infinite;
            }
        }

        div.close-screen {
            position: absolute;
            top: -30px;
            right: -30px;

            font-size: 48px; /* Default font size */
            cursor: pointer;
            color: white;
            z-index: 100;
            border-radius: 5px;
            letter-spacing: 2px;
            height: 70px;
            box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.25);
            border: none;
            background-color: #b387c6;

            body.ios & {
                font-size: 64px;
                padding: 16px 12px 60px 14px;
            }

            body.windows & {
                padding: 6px 12px 16px 14px;
            }

            &:hover {
                color: #b387c6;
                background-color: white;
                cursor: pointer;
            }

            &:active {
                transform: scale(0.95);
                box-shadow: none;
            }
        }

        div.run-complete-btn {
            margin-top: 0;
            font-size: 42px;
            color: white;
            padding: 10px 18px;
            border-radius: 5px;
            letter-spacing: 2px;
            margin-bottom: 25px;
            box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.25);
            border: none;
            background-color: #b387c6;
            display: flex;
            pointer-events: all;

            &:hover {
                color: #b387c6;
                background-color: white;
                cursor: pointer;
            }

            &:active {
                transform: scale(0.95);
                box-shadow: none;
            }
        }
    }
}
// #endregion

// #region Region Select
div.trail-select {
    display: none;
    // border: 3px solid white;
    border-radius: 8px;
    // height: 525px;
    width: 800px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f2b860;
    pointer-events: all;
    box-shadow: 0px 10px 10px 0px #00000040;

    div.trail-select-inner {
        position: relative;
        display: block;
        height: 100%;
        width: 100%;
        // display: flex;

        div.title {
            padding: 23px 0 30px 34px;
            height: 100px;
            width: 100%;
            color: white;
            font-size: 48px;
            text-align: center;
            color: #b387c6;
            background-color: #efd7a2;
            letter-spacing: 2px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            z-index: 99;
        }

        div.close-screen {
            position: absolute;
            top: -30px;
            right: -30px;

            font-size: 48px; /* Default font size */
            cursor: pointer;
            color: white;
            z-index: 100;
            border-radius: 5px;
            letter-spacing: 2px;
            height: 70px;
            box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.25);
            border: none;
            background-color: #b387c6;

            body.ios & {
                font-size: 64px;
                padding: 16px 12px 60px 14px;
            }

            body.windows & {
                padding: 6px 12px 16px 14px;
            }

            &:hover {
                color: #b387c6;
                background-color: white;
                cursor: pointer;
            }

            &:active {
                transform: scale(0.95);
                box-shadow: none;
            }
        }

        div.options {
            position: relative;
            top: -50px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            z-index: 80;

            div.trail-scroll-left-btn {
                left: 0;
            }

            div.trail-scroll-right-btn {
                right: 15px;
            }

            div.trail-scroll-left-btn,
            div.trail-scroll-right-btn {
                width: 50px;
                color: white;
                opacity: 0.75;
                cursor: pointer;
                position: absolute;

                span.material-icons {
                    width: 50px;
                    font-size: 64px !important;
                }

                &:hover {
                    color: #b387c6;
                    cursor: pointer;
                }

                &:active {
                    transform: scale(0.75);
                    box-shadow: none;
                }
            }

            div.center-opt,
            div.left-opt,
            div.right-opt {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                div.trail-option-name {
                    color: white;
                    letter-spacing: 2px;
                    font-size: 24px;
                    margin-bottom: 10px;
                    color: #3a9393;
                }
            }

            div.left-opt,
            div.right-opt {
                margin: 0 20px;
                opacity: 0.6;
                transform: scale(0.65);
                // border: 1px solid green;
                z-index: 900;
                position: relative;
                // top: -30px;

                div.trail-option-name {
                    color: white;
                }
            }

            div.left-opt {
                left: 30px;
            }

            div.right-opt {
                right: 30px;
            }

            div.center-opt {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                //border: 1px solid green;
                z-index: 999;
                position: relative;
                top: 45px;
            }

            div.trail-buttons {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                span.trail-active {
                    display: none;
                    font-size: 46px;
                    color: white;
                    height: 70px;
                    padding: 25px 0 10px 0;
                }

                button.select-trail-btn {
                    padding: 18px 10px 0 15px !important;
                    align-items: center;
                    justify-content: center;
                }

                button.unlock-trail-btn {
                    padding: 6px 9px 0 9px;
                }

                button.choose {
                    color: white;
                    font-size: 47px;
                    border-radius: 5px;
                    letter-spacing: 2px;
                    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.25);
                    border: none;
                    background-color: #b387c6;
                    position: relative;
                    top: -10px;
                    height: 70px;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        color: #b387c6;
                        background-color: white;
                        cursor: pointer;
                    }

                    &:active {
                        transform: scale(0.95);
                        box-shadow: none;
                    }
                }
            }

            div.remove {
                position: absolute;
                bottom: -50px;
                display: flex;
                left: 5%;
                scale: -50%;
                transform: translateX(-10%);
                flex-direction: row;
                align-items: center;
                justify-content: center;
                z-index: 80;

                button.remove-trail-btn {
                    align-items: center;
                    justify-content: center;
                }

                button.choose {
                    color: white;
                    font-size: 24px;
                    border-radius: 5px;
                    letter-spacing: 2px;
                    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.25);
                    border: none;
                    background-color: #b387c6;
                    position: relative;
                    top: -10px;
                    height: 70px;

                    &:hover {
                        color: #b387c6;
                        background-color: white;
                        cursor: pointer;
                    }

                    &:active {
                        transform: scale(0.95);
                        box-shadow: none;
                    }
                }
            }
        }
    }
}
// #endregion

// #region Restart Run Warning
div.restart-run-warning {
    display: none;
    border-radius: 8px;
    width: 350px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f2b860;
    pointer-events: all;
    box-shadow: 0px 10px 10px 0px #00000040;

    div.restart-run-warning-inner {
        position: relative;
        display: block;
        height: 100%;
        width: 100%;
        text-align: center;
        // display: flex;

        div.restart-run-title {
            padding: 23px 0 30px 34px;
            height: 100px;
            width: 100%;
            color: white;
            font-size: 48px;
            text-align: center;
            color: #b387c6;
            background-color: #efd7a2;
            letter-spacing: 2px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            z-index: 99;
        }

        div.close-screen {
            position: absolute;
            top: -30px;
            right: -30px;

            font-size: 48px; /* Default font size */
            cursor: pointer;
            color: white;
            z-index: 100;
            border-radius: 5px;
            letter-spacing: 2px;
            height: 70px;
            box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.25);
            border: none;
            background-color: #b387c6;

            body.ios & {
                font-size: 64px;
                padding: 16px 12px 60px 14px;
            }

            body.windows & {
                padding: 6px 12px 16px 14px;
            }

            &:hover {
                color: #b387c6;
                background-color: white;
                cursor: pointer;
            }

            &:active {
                transform: scale(0.95);
                box-shadow: none;
            }
        }

        p.warning {
            color: white;
            font-size: 32px;
            padding: 0 30px;
        }

        button.restart-run-btn-confirm {
            margin-top: 25px;
            color: white;
            padding: 24px 12px 8px 12px;
            font-size: 47px;
            border-radius: 5px;
            letter-spacing: 2px;
            margin-bottom: 25px;
            box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.25);
            border: none;
            background-color: #b387c6;

            &:hover {
                color: #b387c6;
                background-color: white;
                cursor: pointer;
            }

            &:active {
                transform: scale(0.95);
                box-shadow: none;
            }
        }
    }
}
// #endregion

// #region View Settings
div.view-settings {
    display: none;
    border-radius: 8px;
    width: 500px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f2b860;
    pointer-events: all;
    box-shadow: 0px 10px 10px 0px #00000040;

    div.view-settings-inner {
        position: relative;
        display: block;
        height: 100%;
        width: 100%;
        text-align: center;
        // display: flex;

        div.view-settings-title {
            padding: 23px 0 30px 34px;
            height: 100px;
            width: 100%;
            color: white;
            font-size: 48px;
            text-align: center;
            color: #b387c6;
            background-color: #efd7a2;
            letter-spacing: 2px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            z-index: 99;
        }

        h3.settings-header {
            color: #b387c6;
            font-size: 36px;
        }

        div.close-screen {
            position: absolute;
            top: -30px;
            right: -30px;

            font-size: 48px; /* Default font size */
            cursor: pointer;
            color: white;
            z-index: 100;
            border-radius: 5px;
            letter-spacing: 2px;
            height: 70px;
            box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.25);
            border: none;
            background-color: #b387c6;

            body.ios & {
                font-size: 64px;
                padding: 16px 12px 60px 14px;
            }

            body.windows & {
                padding: 6px 12px 16px 14px;
            }

            &:hover {
                color: #b387c6;
                background-color: white;
                cursor: pointer;
            }

            &:active {
                transform: scale(0.95);
                box-shadow: none;
            }
        }

        p.privacy-policy {
            color: #fff;
            font-size: 32px;
            padding: 0 30px;
        }
    }
}

.shadow-parent {
    margin: auto;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
}

.bacon-reward {
    position: absolute;
    height: 200px;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
}

.discord-container {
    position: relative;
    margin-bottom: 20px;
    margin-top: -20px;
    animation: wiggle 0.4s ease-in-out infinite alternate;
}

@keyframes wiggle {
    0% {
        transform: rotate(-2deg);
    }
    100% {
        transform: rotate(2deg);
    }
}

.discord-container:hover {
    transform: scale(1.05); /* Scaling up on hover */
}

.discord-container:active {
    transform: scale(0.9); /* Scaling down on click */
}

/* White background with shadow effect */
.shadow-btn {
    margin: auto;
    background-color: #efd7a2;
    padding: 1rem; /* Equivalent to p-4 */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Basic shadow similar to Tailwind's default */
    border-radius: 0.5rem; /* Adjust to match the look */
}

.shadow-btn {
    text-align: center;
    border: 4px solid white; /* Border with thickness */
    border-radius: 0.5rem; /* Rounded corners */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Shadow effect */
    cursor: pointer; /* Pointer cursor for interactivity */
    transition: transform 0.1s ease-in-out; /* Smooth scale transition */
}

/* Center the image vertically and horizontally with specific height */
.shadow-btn img {
    height: 50px; /* Equivalent to h-20 */
    margin: auto;
}

.shadow-btn p {
    margin-bottom: 0px;
}
// #endregion

// #region Character Select
div.character-select {
    display: none;
    // border: 3px solid white;
    border-radius: 8px;
    // height: 525px;
    width: 800px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f2b860;
    pointer-events: all;
    box-shadow: 0px 10px 10px 0px #00000040;

    div.character-select-inner {
        position: relative;
        display: block;
        height: 100%;
        width: 100%;
        // display: flex;

        div.title {
            padding: 23px 0 30px 34px;
            height: 100px;
            width: 100%;
            color: white;
            font-size: 48px;
            text-align: center;
            color: #b387c6;
            background-color: #efd7a2;
            letter-spacing: 2px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            z-index: 99;
        }

        div.close-screen {
            position: absolute;
            top: -30px;
            right: -30px;

            font-size: 48px; /* Default font size */
            cursor: pointer;
            color: white;
            z-index: 100;
            border-radius: 5px;
            letter-spacing: 2px;
            height: 70px;
            box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.25);
            border: none;
            background-color: #b387c6;

            body.ios & {
                font-size: 64px;
                padding: 16px 12px 60px 14px;
            }

            body.windows & {
                padding: 6px 12px 16px 14px;
            }

            &:hover {
                color: #b387c6;
                background-color: white;
                cursor: pointer;
            }

            &:active {
                transform: scale(0.95);
                box-shadow: none;
            }
        }

        div.options {
            position: relative;
            top: -50px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            z-index: 80;

            div.character-scroll-left-btn {
                left: 0;
            }

            div.character-scroll-right-btn {
                right: 15px;
            }

            div.character-scroll-left-btn,
            div.character-scroll-right-btn {
                width: 50px;
                color: white;
                opacity: 0.75;
                cursor: pointer;
                position: absolute;

                span.material-icons {
                    width: 50px;
                    font-size: 64px !important;
                }

                &:hover {
                    color: #b387c6;
                    cursor: pointer;
                }

                &:active {
                    transform: scale(0.75);
                    box-shadow: none;
                }
            }

            div.center-opt,
            div.left-opt,
            div.right-opt {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                div.character-option-name {
                    color: white;
                    letter-spacing: 2px;
                    font-size: 24px;
                    margin-bottom: 10px;
                    color: #3a9393;
                }
            }

            div.left-opt,
            div.right-opt {
                margin: 0 20px;
                opacity: 0.6;
                transform: scale(0.65);
                // border: 1px solid green;
                z-index: 900;
                position: relative;
                top: -30px;

                div.character-option-name {
                    color: white;
                }
            }

            div.center-opt {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                // border: 1px solid green;
                transform: scale(1.25);
                z-index: 999;

                span.character-active {
                    display: none;
                    font-size: 46px;
                    color: white;
                    position: relative;
                    top: -15px;
                    padding: 70px 0 10px 0;
                }

                button.select-character-btn {
                    // display: none !important;
                    padding: 15px 10px 0 15px !important;
                    align-items: center;
                    justify-content: center;
                }

                button.unlock-character-btn,
                button.unlock-discord-btn {
                    // display: none !important;
                    padding-top: 5px;
                }

                button.choose {
                    padding-top: 5px;
                    margin-top: 25px;
                    color: white;
                    padding: 8px 12px 8px 12px;
                    font-size: 47px;
                    border-radius: 5px;
                    letter-spacing: 2px;
                    margin-bottom: 25px;
                    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.25);
                    border: none;
                    background-color: #b387c6;

                    &:hover {
                        color: #b387c6;
                        background-color: white;
                        cursor: pointer;
                    }

                    &:active {
                        transform: scale(0.95);
                        box-shadow: none;
                    }
                }
            }
        }
    }
}
// #endregion

//#region UI Pop Loader
.ui-pop-loader {
    height: 60px;
    width: 260px;
    border: 3px solid white;
    border-radius: 8px;
    text-align: center;
    background-color: darken(#6f6053, 10%);
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 60px;
    transform: scale(1.5);

    .ui-pop-loader-inner {
        height: 100%;
        width: 100%;
        position: relative;

        .ui-pop-loader-text {
            padding-top: 12px;
            position: absolute;
            left: 0;
            right: 0;
            font-size: 40px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 100;
        }

        .ui-pop-loader-progress {
            z-index: 80;
            border-radius: 8px;
            width: 25%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #6f6053;
        }
    }
}

.discord-info {
    padding: 0;
    margin: auto;
    margin-top: 8px;
    margin-left: 8px;
}

.unlock-discord-btn {
    padding: 10px !important;
}

/* Parent container for the shadow effect */

.hud-ad-reward {
    align-self: flex-start;
    margin: 20px;
    transform-origin: left;
}

.container {
    position: relative;
    z-index: 10;
    cursor: pointer;
    pointer-events: auto;
    // transform: scale(0.6);
    // transform-origin: left;
}

.animate-wiggle-light {
    /* Add your animation keyframes for wiggle-light */
}

.frame {
    height: 8rem; /* Equivalent to h-32 */
    width: auto;
    pointer-events: none;
}

.title {
    pointer-events: none;
    transform: rotate(-6deg) translateY(-12.5%);
    text-transform: uppercase;
    font-family: "YourFontHere", sans-serif; /* Replace with actual font */
    position: absolute;
    top: 0;
    font-size: 2.25rem; /* Equivalent to text-4xl */
    margin-left: 1rem; /* Equivalent to ml-4 */
    white-space: normal;
    width: 2.5rem; /* Equivalent to w-10 */
    line-height: 2rem; /* Equivalent to leading-8 */

    color: #000;
    text-shadow: -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff, 2px 2px 0 #fff, -3px 0 0 #fff, 3px 0 0 #fff, 0 -3px 0 #fff, 0 3px 0 #fff;
    font-weight: bold;
}

.ad-icon {
    pointer-events: none;
    position: absolute;
    height: 3rem; /* Equivalent to h-12 */
    margin-left: 1.25rem; /* Equivalent to ml-5 */
    margin-bottom: 1.25rem; /* Equivalent to mb-5 */
    bottom: 0;
    left: 0;
}

.character {
    pointer-events: none;
    transform: rotate(-6deg) translateX(25%) translateY(25%);
    height: 10rem; /* Equivalent to h-40 */
    position: absolute;
    right: 2.5rem; /* Equivalent to right-5 */
    bottom: 1.5rem; /* Equivalent to bottom-6 */
}

.close-button {
    position: absolute;
    top: 0;
    right: 0;
    display: flex; /* Ensure the content is centered */
    align-items: center;
    justify-content: center;
    border: 2px solid black; /* Equivalent to border-black border-2 */
    border-radius: 50%; /* Equivalent to rounded-full */
    width: 2rem; /* Explicitly set the width */
    height: 2rem; /* Explicitly set the height to match the width */
    transform: translateY(-25%) translateX(25%);
    background-color: #ff4d4d; /* Replace with the actual Tailwind `bg-danger` color */
    cursor: pointer;
    transition: all 0.2s ease-in-out; /* Equivalent to transition-all duration-200 */
    padding: 15px;
}

.close-button:hover {
    background-color: #ff6666; /* Replace with the actual Tailwind `bg-danger-light` color */
}

.close-icon {
    height: 1.5rem; /* Equivalent to h-8 */
}

@keyframes wiggle-light {
    0%,
    100% {
        transform: rotate(-2deg);
    }
    50% {
        transform: rotate(2deg);
    }
}

.animate-wiggle-light {
    animation: wiggle-light 1s infinite; /* Adjust duration and repetition as needed */
}

.mobile .mobile-scale-60 {
    transform: scale(0.6);

    margin-top: -20px;
}
