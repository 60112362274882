/*************************************************************/
/*                                                           */
/*         Hiding And Showing Shiz On Mobile                 */
/*                                                           */
/*************************************************************/
//#region Mobile
html.mobile {
    div.top-left-hud {
        top: 0px !important;
        left: unset !important;
        right: unset !important;
    }

    div.top-center-hud {
        top: 25px !important;
        transform: scale(0.65) !important;

        div.height {
            display: none !important;
        }

        div.falls {
            display: none !important;
        }
    }

    @media screen and (max-width: 500px) {
        div.loading-splash {
            .game-logo {
                position: relative;
                top: 25px;
                height: 345px;
            }

            .loading {
                position: relative;
                top: -55px;
                transform: scale(0.75) !important;
            }
        }
    }

    @media screen and (max-width: 450px) {
        div.loading-splash {
            .game-logo {
                position: relative;
                top: 25px;
                height: 315px;
            }

            .loading {
                position: relative;
                top: -55px;
                transform: scale(0.75) !important;
            }
        }
    }

    @media screen and (max-width: 400px) {
        div.loading-splash {
            .game-logo {
                position: relative;
                top: 25px;
                height: 275px;
            }

            .loading {
                position: relative;
                top: -55px;
                transform: scale(0.65) !important;
            }
        }
    }

    @media screen and (max-width: 350px) {
        div.loading-splash {
            .game-logo {
                position: relative;
                top: 25px;
                height: 235px;
            }

            .loading {
                position: relative;
                top: -55px;
                transform: scale(0.55) !important;
            }
        }
    }

    @media screen and (max-width: 300px) {
        div.loading-splash {
            .game-logo {
                position: relative;
                top: 25px;
                height: 215px;
            }

            .loading {
                position: relative;
                top: -55px;
                transform: scale(0.45) !important;
            }
        }
    }
}
// #endregion

//#region Large Screen 4 (3840x2160)
/*************************************************************/
/*                                                           */
/*              BREAKPOINT: Large Screen 4                   */
/*                                                           */
/*************************************************************/
@media screen and (max-width: 3841px), (max-height: 2161px) {
    div.loading-splash {
        .game-logo {
            position: relative;
            top: 25px;
            height: 950px;
        }

        .loading {
            position: relative;
            top: -75px;
            transform: scale(1.55) !important;
        }
    }

    div.top-left-hud {
        top: 35px !important;
        left: 150px !important;
    }

    div.top-center-hud {
        top: 35px !important;
    }

    div.change-username {
        transform: scale(2.25) !important;
    }

    div.emoji-selector {
        transform: scale(2.5) !important;
    }

    div.run-complete {
        transform: scale(2.25) !important;
    }

    div.character-select,
    div.trail-select,
    div.restart-run-warning,
    div.view-settings {
        transform: scale(1.85) !important;
    }

    .play-btn {
        transform: scale(5) !important;
        bottom: 150px !important;
    }

    div.ui-pop-loader {
        transform: scale(2.5) !important;
        bottom: 100px !important;
    }

    div.hud p.alert {
        transform: scale(2) !important;
        margin-top: 10% !important;
    }
}
//#endregion

//#region Large Screen 3 (2560x1440)
/*************************************************************/
/*                                                           */
/*              BREAKPOINT: Large Screen 3                   */
/*                                                           */
/*************************************************************/
@media screen and (max-width: 2561px), (max-height: 1441px) {
    div.loading-splash {
        .game-logo {
            position: relative;
            top: 25px;
            height: 950px;
        }

        .loading {
            position: relative;
            top: -75px;
            transform: scale(1.55) !important;
        }
    }

    div.top-left-hud {
        top: 35px !important;
        left: 150px !important;
    }

    div.top-center-hud {
        top: 35px !important;
    }

    div.change-username {
        transform: scale(2.25) !important;
    }

    div.emoji-selector {
        transform: scale(2.5) !important;
    }

    div.run-complete {
        transform: scale(2.25) !important;
    }

    div.character-select,
    div.trail-select,
    div.restart-run-warning,
    div.view-settings {
        transform: scale(1.85) !important;
    }

    .play-btn {
        transform: scale(5) !important;
        bottom: 150px !important;
    }

    div.ui-pop-loader {
        transform: scale(2.5) !important;
        bottom: 100px !important;
    }

    div.hud p.alert {
        transform: scale(2) !important;
        margin-top: 10% !important;
    }
}
//#endregion

//#region Large Screen 2 (1920x1080)
/*************************************************************/
/*                                                           */
/*              BREAKPOINT: Large Screen 2                   */
/*                                                           */
/*************************************************************/
@media screen and (max-width: 1921px), (max-height: 1081px) {
    div.loading-splash {
        .game-logo {
            position: relative;
            top: 25px;
            height: 750px;
        }

        .loading {
            position: relative;
            top: -75px;
            transform: scale(1.25) !important;
        }
    }

    div.top-left-hud {
        left: 50px !important;
    }

    div.top-center-hud {
        top: 15px !important;
    }

    div.change-username {
        transform: scale(1.65) !important;
    }

    div.emoji-selector {
        transform: scale(1.65) !important;
    }

    div.run-complete {
        transform: scale(1.75) !important;
    }

    div.character-select,
    div.trail-select,
    div.restart-run-warning,
    div.view-settings {
        transform: scale(1.55) !important;
    }

    .play-btn {
        transform: scale(3.25) !important;
        bottom: 150px !important;
    }

    div.ui-pop-loader {
        transform: scale(1.75) !important;
        bottom: 75px !important;
    }

    div.hud p.alert {
        transform: scale(1.25) !important;
        margin-top: 10% !important;
    }
}
//#endregion

//#region Large Screen 1 (1600x900)
/*************************************************************/
/*                                                           */
/*              BREAKPOINT: Large Screen 1                   */
/*                                                           */
/*************************************************************/
@media screen and (max-width: 1601px), (max-height: 901px) {
    div.loading-splash {
        .game-logo {
            position: relative;
            top: 25px;
            height: 650px;
        }

        .loading {
            position: relative;
            top: -75px;
        }
    }

    div.top-center-hud {
        top: 15px !important;
    }

    div.change-username {
        transform: scale(1.25) !important;
    }

    div.emoji-selector {
        transform: scale(1.45) !important;
    }

    div.run-complete {
        transform: scale(1.5) !important;
    }

    div.character-select,
    div.trail-select,
    div.restart-run-warning,
    div.view-settings {
        transform: scale(1.25) !important;
    }

    .play-btn {
        transform: scale(3) !important;
        bottom: 100px !important;
    }

    div.ui-pop-loader {
        transform: scale(1.75) !important;
        bottom: 75px !important;
    }

    div.hud p.alert {
        transform: scale(1.25) !important;
        margin-top: 10% !important;
    }
}
//#endregion

//#region Small Screen 3 (1440x900)
/*************************************************************/
/*                                                           */
/*              BREAKPOINT: Small Screen 3                   */
/*                                                           */
/*************************************************************/
@media screen and (max-width: 1441px), (max-height: 901px) {
    div.loading-splash {
        .game-logo {
            position: relative;
            top: 25px;
            height: 650px;
        }

        .loading {
            position: relative;
            top: -75px;
        }
    }

    div.change-username {
        transform: scale(1.25) !important;
    }

    div.emoji-selector {
        transform: scale(1.45) !important;
    }

    div.run-complete {
        transform: scale(1.5) !important;
    }

    div.character-select,
    div.trail-select,
    div.restart-run-warning,
    div.view-settings {
        transform: scale(1.25) !important;
    }

    .play-btn {
        transform: scale(3) !important;
        bottom: 100px !important;
    }

    div.ui-pop-loader {
        transform: scale(1.75) !important;
        bottom: 75px !important;
    }

    div.hud p.alert {
        transform: scale(1.25) !important;
        margin-top: 10% !important;
    }
}
//#endregion

//#region Small Screen 2 (1376x768)
/*************************************************************/
/*                                                           */
/*              BREAKPOINT: Small Screen 2                   */
/*                                                           */
/*************************************************************/
@media screen and (max-width: 1377px), (max-height: 769px) {
    div.loading-splash {
        .game-logo {
            position: relative;
            top: 25px;
            height: 500px;
        }

        .loading {
            position: relative;
            top: -55px;
        }
    }

    div.change-username {
        transform: scale(0.85) !important;
    }

    div.emoji-selector {
        transform: scale(0.95) !important;
    }

    div.run-complete {
        transform: scale(1) !important;
    }

    div.character-select,
    div.trail-select,
    div.restart-run-warning,
    div.view-settings {
        transform: scale(0.85) !important;
    }

    .play-btn {
        transform: scale(2.25) !important;
        bottom: 35px !important;
    }

    div.ui-pop-loader {
        transform: scale(1.25) !important;
        bottom: 35px !important;
    }

    div.hud p.alert {
        transform: scale(0.75) !important;
        margin-top: 10% !important;
    }
}
//#endregion

//#region Small Screen 1 (1280x720)
/*************************************************************/
/*                                                           */
/*              BREAKPOINT: Small Screen 1                   */
/*                                                           */
/*************************************************************/
@media screen and (max-width: 1281px), (max-height: 721px) {
    div.loading-splash {
        .game-logo {
            position: relative;
            top: 25px;
            height: 500px;
        }

        .loading {
            position: relative;
            top: -55px;
        }
    }

    div.change-username {
        transform: scale(0.85) !important;
    }

    div.emoji-selector {
        transform: scale(0.95) !important;
    }

    div.run-complete {
        transform: scale(1) !important;
    }

    div.character-select,
    div.trail-select,
    div.restart-run-warning,
    div.view-settings {
        transform: scale(0.85) !important;
    }

    .play-btn {
        transform: scale(2.25) !important;
        bottom: 35px !important;
    }

    div.ui-pop-loader {
        transform: scale(1.25) !important;
        bottom: 35px !important;
    }

    div.hud p.alert {
        transform: scale(0.75) !important;
        margin-top: 10% !important;
    }
}
//#endregion

//#region Poki Embedded Large (1031x580)
/*************************************************************/
/*                                                           */
/*           BREAKPOINT: Poki Embedded Large                 */
/*                                                           */
/*************************************************************/
@media screen and (max-width: 1032px), (max-height: 581px) {
    div.loading-splash {
        .game-logo {
            position: relative;
            top: 50px;
            height: 500px;
        }

        .loading {
            position: relative;
            top: -55px;
            transform: scale(0.85) !important;
        }
    }

    div.emoji-selector {
        transform: scale(0.95) !important;
    }

    div.run-complete {
        transform: scale(1) !important;
    }

    div.character-select,
    div.trail-select,
    div.restart-run-warning,
    div.view-settings {
        transform: scale(0.85) !important;
    }

    .play-btn {
        transform: scale(2.25) !important;
        bottom: 35px !important;
    }

    div.ui-pop-loader {
        transform: scale(1.25) !important;
        bottom: 35px !important;
    }

    div.hud p.alert {
        transform: scale(0.75) !important;
        margin-top: 10% !important;
    }

    div.top-center-hud {
        top: 0px !important;
        transform: scale(1) !important;
    }

    div.change-username {
        transform: scale(0.85) !important;
    }

    div.top-left-hud {
        top: 5px !important;
        left: 5px !important;
    }
}
//#endregion

@media screen and (max-width: 837px) {
    .top-ui-container {
        justify-content: space-around !important;
        gap: 25px !important;
        margin-top: 10px; //hack, until we resolve timer margin

        .top-center-hud {
            margin-top: 0px !important;
        }

        .run-duration {
            margin-top: 0px !important;
        }
    }
}

//#region Poki Embedded Medium (836x470)
/*************************************************************/
/*                                                           */
/*           BREAKPOINT: Poki Embedded Medium                 */
/*                                                           */
/*************************************************************/
@media screen and (max-width: 837px), (max-height: 471px) {
    div.loading-splash {
        .game-logo {
            height: 350px;
            position: relative;
            top: 50px;
        }

        .loading {
            // scale it down 50%
            transform: scale(0.75) !important;
            position: relative;
            top: -25px;
        }
    }

    div.change-username {
        transform: scale(0.73) !important;
    }

    div.emoji-selector {
        transform: scale(0.75) !important;
    }

    div.run-complete {
        transform: scale(0.85) !important;
    }

    div.character-select,
    div.trail-select,
    div.restart-run-warning,
    div.view-settings {
        transform: scale(0.69) !important;
    }

    .play-btn {
        transform: scale(1.75) !important;
        bottom: 5px !important;
    }

    div.ui-pop-loader {
        transform: scale(1.25) !important;
        bottom: 35px !important;
    }

    div.hud p.alert {
        transform: scale(0.75) !important;
        margin-top: 10% !important;
    }

    div.top-left-hud {
        top: -5px !important;
        left: unset !important;
        right: unset !important;
    }

    div.top-center-hud {
        top: 25px !important;
        transform: scale(0.75) !important;

        div.falls {
            top: -51px !important;
            left: -90px !important;
        }

        div.height {
            top: -40px !important;
            left: 222px !important;
        }
    }
}
//#endregion

//#region Poki Embedded Small (640x360)
/*************************************************************/
/*                                                           */
/*           BREAKPOINT: Poki Embedded Small                 */
/*                                                           */
/*************************************************************/
@media screen and (max-width: 641px), (max-height: 361px) {
    div.loading-splash {
        .game-logo {
            height: 300px;
            position: relative;
            top: 55px;
        }

        .loading {
            // scale it down 50%
            transform: scale(0.65) !important;
            position: relative;
            top: -50px;
        }
    }

    div.change-username {
        transform: scale(0.5) !important;
    }

    div.emoji-selector {
        transform: scale(0.6) !important;
    }

    div.run-complete {
        transform: scale(0.6) !important;
    }

    div.character-select,
    div.trail-select,
    div.restart-run-warning,
    div.view-settings {
        transform: scale(0.47) !important;
    }

    .play-btn {
        transform: scale(1.5) !important;
        bottom: 5px !important;
    }

    div.ui-pop-loader {
        transform: scale(1) !important;
        bottom: 5px !important;
    }

    div.top-left-hud {
        top: 0px !important;
        left: unset !important;
        right: unset !important;
    }

    div.hud p.alert {
        transform: scale(0.75) !important;
        margin-top: 10% !important;
    }

    div.top-center-hud {
        top: 25px !important;
        transform: scale(0.65) !important;

        div.falls {
            top: -51px !important;
            left: -90px !important;
        }

        div.height {
            top: -40px !important;
            left: 222px !important;
        }
    }
}
//#endregion
